import { Link, Redirect } from 'react-router-dom'
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import {useEffect} from "react";

export function HomePage() {
  const isMobile = window.innerWidth <= 576
  console.log({isMobile})

  useEffect(() => {
    window.location.replace('https://news.robotanan.net/')
  }, [])

  return <></>

  return (
    <>
      <div className='homepage'>

        {/*nav menu start*/}
        <div className="container" id='home'>
          <div className="row">
            <div className="col-xs-12 col-sm-4 text-center">
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-anan.png')} className='h-60px homepage-logo' />
            </div>
            <div className="col-xs-12 col-sm-4 px-0">
              <ul className="nav justify-content-center">
                <li className="nav-item homepage-nav-item mt-0 mt-sm-9">
                  <a className="homepage-nav-link" href="#home">Home</a>
                </li>
                <li className="nav-item homepage-nav-item mt-0 mt-sm-9">
                  <a className="homepage-nav-link" href="#about">About</a>
                </li>
                <li className="nav-item homepage-nav-item mt-0 mt-sm-9">
                  <a className="homepage-nav-link" href="#partner">Partner</a>
                </li>
                <li className="nav-item homepage-nav-item mt-0 mt-sm-9">
                  <a className="homepage-nav-link" href="#service">Service</a>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-4 text-center p-0 p-sm-4">
              <Link to='dashboard'>
                <span className='dashboard-button'>
                  Go to dashboard
                </span>
              </Link>
            </div>
          </div>
        </div>
        {/*nav menu end*/}

        {/*body desktop start*/}
        {!isMobile && <>
          <div className="container-fluid px-0">
            <div className="row">
              <img alt='home-1' src={toAbsoluteUrl('/media/logos/p1.png')} className='w-100'/>
            </div>
            <div className="row" id='about'>
              <img alt='home-2' src={toAbsoluteUrl('/media/logos/p2.png')} className='w-100'/>
            </div>
            <div className="row" id='partner'>
              <img alt='home-3' src={toAbsoluteUrl('/media/logos/p3.png')} className='w-100'/>
            </div>
            <div className="row" id='service'>
              <img alt='home-4' src={toAbsoluteUrl('/media/logos/p4.png')} className='w-100'/>
            </div>
          </div>
        </>}
        {/*body desktop end*/}

        {/*body mobile start*/}
        {isMobile && <>
          <div className="container-fluid px-0">
            <div className="row">
              <img alt='home-e1' src={toAbsoluteUrl('/media/logos/m1.png')} className='w-100'/>
            </div>
            <div className="row" id='about'>
              <img alt='home-e2' src={toAbsoluteUrl('/media/logos/m2.png')} className='w-100'/>
            </div>
            <div className="row" id='partner'>
              <img alt='home-e3' src={toAbsoluteUrl('/media/logos/m3.png')} className='w-100'/>
            </div>
            <div className="row" id='service'>
              <img alt='home-e3' src={toAbsoluteUrl('/media/logos/m4.png')} className='w-100'/>
            </div>
          </div>
        </>}
        {/*body mobile end*/}

        {/*bottom menu start*/}
        <div className="container">
          <div className="row mt-3 mt-sm-12">
            <div className="col-xs-12 col-sm-6">
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-anan.png')} className='h-60px my-5' />
              <p className='homepage-bottom-content-name'>Anan Global Technology Joint Stock Company</p>
              <p className='homepage-bottom-content-item'>ANAN - LIFE CHANGING TECHNOLOGY</p>
              {/*<p className='homepage-bottom-content-title'>Registered office address</p>
              <p className='homepage-bottom-content-item'>BT 5/8, The Mansions, Hanoi Parkcity, Ha Dong, Ha Noi</p>*/}
              <p className='homepage-bottom-content-title'>Contact us:</p>
              <p className='homepage-bottom-content-item'>support@Anan.com</p>
            </div>
            <div className="col-xs-12 col-sm-6">
              <div className="row">
                <div className="col-4">
                  <p className='homepage-bottom-menu-title'>Resources</p>
                  <p className='homepage-bottom-menu-item'>Customer Stories</p>
                  <p className='homepage-bottom-menu-item'>Knowledge Base</p>
                  <p className='homepage-bottom-menu-item'>Contact Us</p>
                </div>
                <div className="col-4">
                  <p className='homepage-bottom-menu-title'>Products</p>
                  <p className='homepage-bottom-menu-item'>Corporate Partners</p>
                  <p className='homepage-bottom-menu-item'>Secure Identity</p>
                  <p className='homepage-bottom-menu-item'>Legal Help</p>
                </div>
                <div className="col-4">
                  <p className='homepage-bottom-menu-title'>Company</p>
                  <p className='homepage-bottom-menu-item'>About Us</p>
                  <p className='homepage-bottom-menu-item'>Careers</p>
                  <p className='homepage-bottom-menu-item'>Terms of Use</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*bottom menu end*/}

        {/*bottom page start*/}
        <div className="container text-center">
          <hr/>
          <p>Anan © 2024. All Rights Reserved.</p>
        </div>
        {/*bottom page end*/}

      </div>
    </>
  )
}
