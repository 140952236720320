/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from "axios";
import {shallowEqual, useSelector} from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, useParams, Link } from "react-router-dom";
import {KTSVG} from "../../../_metronic/helpers";
import {BACKEND_URL} from "../../modules/auth/redux/AuthCRUD";

const ViewInvestor = () => {
  const {id} = useParams()
  const intl = useIntl()
  const [provinces, setProvinces] = useState([])
  const [shareholderAccountQuantities, setShareholderAccountQuantities] = useState([])
  const [province, setProvince] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [validateErrorMsgArr, setValidateErrorMsgArr] = useState([])
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [provinceId, setProvinceId] = useState(1)
  const [investor, setInvestor] = useState({})
  const [depositHistoryArr, setDepositHistoryArr] = useState([])
  const [accountHistoryArr, setAccountHistoryArr] = useState([])
  const [stockHistoryArr, setStockHistoryArr] = useState([])
  const [commissionArr, setCommissionArr] = useState([])
  const [f1Arr, setF1Arr] = useState([])
  const [depositSum, setDepositSum] = useState(0)
  const [accountSum, setAccountSum] = useState(0)
  const [stockSum, setStockSum] = useState(0)
  const [commissionSum, setCommissionSum] = useState(0)
  const accessToken = useSelector(({auth}) => auth.accessToken, shallowEqual)
  const history = useHistory()

  useEffect(() => {
    axios.post(`${BACKEND_URL}/investor/view/${id}`, {
      access_token: accessToken
    }).then(data => data.data)
      .then(resData => {
        console.log({resData})
        if (resData.error_code === 1) {
          const {investor} = resData.data
          const depositHistoryArr = investor.deposit_history ?? []
          const accountHistoryArr = investor.investor_account_quantity ?? []
          const stockHistoryArr = investor.investor_stock_quantity ?? []
          const commissionArr = investor.commission ?? []
          const f1Arr = investor.f1 ?? []
          let depositSum = 0;
          let accountSum = 0;
          let stockSum = 0;
          let commissionSum = 0;
          if (depositHistoryArr.length >= 1) {
            depositHistoryArr.forEach((depositHistory) => depositSum += depositHistory.usdt)
          }
          if (accountHistoryArr.length >= 1) {
            accountHistoryArr.forEach((accountHistory) => accountSum += accountHistory.account_quantity)
          }
          if (stockHistoryArr.length >= 1) {
            stockHistoryArr.forEach((stockHistory) => stockSum += stockHistory.stock_quantity)
          }
          if (commissionArr.length >= 1) {
            commissionArr.forEach((commission) => commissionSum += commission.value)
          }
          setDepositHistoryArr(depositHistoryArr)
          setAccountHistoryArr(accountHistoryArr)
          setStockHistoryArr(stockHistoryArr)
          setCommissionArr(commissionArr)

          setDepositSum(depositSum)
          setAccountSum(accountSum)
          setStockSum(stockSum)
          setCommissionSum(commissionSum)

          setF1Arr(f1Arr)

          setName(investor.name)
          setPhone(investor.phone)
          setEmail(investor.email ?? '')
          setInvestor(investor)
        } else {
          toast("Đã xảy ra lỗi, vui lòng liên hệ quản trị viên", {
            onClose: () => {
              history.push('/manage-investor')
            }
          })
        }
      })
    return () => {

    }
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.VIEW_INVESTOR'})}</PageTitle>
      <ToastContainer />
      <div className="card card-custom">
        <div className="card-header">
          <h3 className="card-title">
            Thông tin nhà đầu tư
          </h3>
        </div>
        <form>
          <div className="card-body">
            <div className="form-group">
              <label>Họ và tên</label>
              <input type="text" className="form-control" value={investor.name ?? ''} readOnly={true}/>
            </div>
            <div className="form-group mt-5">
              <label>Số điện thoại</label>
              <input type="text" className="form-control" value={investor.phone ?? ''} readOnly={true}/>
            </div>
            <div className="form-group mt-5">
              <label>Email</label>
              <input type="text" className="form-control" value={investor.email ?? ''} readOnly={true}/>
            </div>

            <div className="form-group mt-5">
              <label>Địa chỉ ví</label>
              <input type="text" className="form-control" value={investor.wallet_address ?? ''} readOnly={true}/>
            </div>

            <div className='col-xxl-4'>
              <div className={`card card-xxl-stretch`}>
                {/* begin::Header */}
                <div className='card-header align-items-center border-0 mt-4'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='fw-bolder mb-2 text-dark'>Deposit History</span>
                    <span className='text-warning fw-bold fs-3'>Summary {depositSum} USDT</span>
                  </h3>
                  <div className='card-toolbar'>
                    {/* begin::Menu */}
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    >
                      <KTSVG
                        path='/media/icons/duotone/Layout/Layout-4-blocks-2.svg'
                        className='svg-icon-2'
                      />
                    </button>
                    {/* end::Menu */}
                  </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body pt-5'>
                  {depositHistoryArr.length < 1 && <>
                    <div className='fw-mormal timeline-content text-muted ps-3'>
                      No information
                    </div>
                  </>}

                  {depositHistoryArr.length >= 1 && <>
                    <div className='timeline-label'>

                      {depositHistoryArr.map((depositHistory) =>
                        <div className='timeline-item' key={depositHistory.id}>
                          <div className='timeline-label fw-bolder text-gray-800 fs-6'>
                            + {depositHistory.usdt}
                          </div>
                          <div className='timeline-badge'>
                            <i className='fa fa-genderless text-warning fs-1'></i>
                          </div>
                          <div className='fw-mormal timeline-content text-muted ps-3'>
                            {depositHistory.change_date.toString().substr(6,2) + '/' + depositHistory.change_date.toString().substr(4,2) + '/' + depositHistory.change_date.toString().substr(0,4)}
                          </div>
                        </div>
                      )}

                    </div>
                  </>}


                </div>
                {/* end: Card Body */}
              </div>
            </div>

            <div className='col-xxl-4'>
              <div className={`card card-xxl-stretch`}>
                {/* begin::Header */}
                <div className='card-header align-items-center border-0 mt-4'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='fw-bolder mb-2 text-dark'>Anan Stock History</span>
                    <span className='text-primary fw-bold fs-3'>Summary {stockSum} STOCKS</span>
                  </h3>
                  <div className='card-toolbar'>
                    {/* begin::Menu */}
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    >
                      <KTSVG
                        path='/media/icons/duotone/Layout/Layout-4-blocks-2.svg'
                        className='svg-icon-2'
                      />
                    </button>
                    {/* end::Menu */}
                  </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body pt-5'>
                  {stockHistoryArr.length < 1 && <>
                    <div className='fw-mormal timeline-content text-muted ps-3'>
                      No information
                    </div>
                  </>}

                  {stockHistoryArr.length >= 1 && <>
                    <div className='timeline-label'>

                      {stockHistoryArr.map((stockHistory) =>
                        <div className='timeline-item' key={stockHistory.id}>
                          <div className='timeline-label fw-bolder text-gray-800 fs-6'>
                            + {stockHistory.stock_quantity}
                          </div>
                          <div className='timeline-badge'>
                            <i className='fa fa-genderless text-warning fs-1'></i>
                          </div>
                          <div className='fw-mormal timeline-content text-muted ps-3'>
                            {stockHistory.change_date.toString().substr(6,2) + '/' + stockHistory.change_date.toString().substr(4,2) + '/' + stockHistory.change_date.toString().substr(0,4)}
                            {stockHistory.partner && ' - ' + stockHistory.partner.username}
                          </div>
                        </div>
                      )}

                    </div>
                  </>}


                </div>
                {/* end: Card Body */}
              </div>
            </div>

            <div className='col-xxl-4'>
              <div className={`card card-xxl-stretch`}>
                {/* begin::Header */}
                <div className='card-header align-items-center border-0 mt-4'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='fw-bolder mb-2 text-dark'>Anbi Account History</span>
                    <span className='text-danger fw-bold fs-3'>Summary {accountSum} ACCOUNTS</span>
                  </h3>
                  <div className='card-toolbar'>
                    {/* begin::Menu */}
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    >
                      <KTSVG
                        path='/media/icons/duotone/Layout/Layout-4-blocks-2.svg'
                        className='svg-icon-2'
                      />
                    </button>
                    {/* end::Menu */}
                  </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body pt-5'>
                  {accountHistoryArr.length < 1 && <>
                    <div className='fw-mormal timeline-content text-muted ps-3'>
                      No information
                    </div>
                  </>}

                  {accountHistoryArr.length >= 1 && <>
                    <div className='timeline-label'>

                      {accountHistoryArr.map((accountHistory) =>
                        <div className='timeline-item' key={accountHistory.id}>
                          <div className='timeline-label fw-bolder text-gray-800 fs-6'>
                            + {accountHistory.account_quantity}
                          </div>
                          <div className='timeline-badge'>
                            <i className='fa fa-genderless text-warning fs-1'></i>
                          </div>
                          <div className='fw-mormal timeline-content text-muted ps-3'>
                            {accountHistory.change_date.toString().substr(6,2) + '/' + accountHistory.change_date.toString().substr(4,2) + '/' + accountHistory.change_date.toString().substr(0,4)}
                          </div>
                        </div>
                      )}

                    </div>
                  </>}


                </div>
                {/* end: Card Body */}
              </div>
            </div>

            <div className='col-xxl-4'>
              <div className={`card card-xxl-stretch`}>
                {/* begin::Header */}
                <div className='card-header align-items-center border-0 mt-4'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='fw-bolder mb-2 text-dark'>Anan Commision History</span>
                    <span className='text-danger fw-bold fs-3'>Summary {commissionSum} USDT</span>
                  </h3>
                  <div className='card-toolbar'>
                    {/* begin::Menu */}
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    >
                      <KTSVG
                        path='/media/icons/duotone/Layout/Layout-4-blocks-2.svg'
                        className='svg-icon-2'
                      />
                    </button>
                    {/* end::Menu */}
                  </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body pt-5'>
                  {commissionArr.length < 1 && <>
                    <div className='fw-mormal timeline-content text-muted ps-3'>
                      No information
                    </div>
                  </>}

                  {commissionArr.length >= 1 && <>
                    <div className='timeline-label'>

                      {commissionArr.map((commission) =>
                        <div className='timeline-item' key={commission.id}>
                          <div className='timeline-label fw-bolder text-gray-800 fs-6 w-60px'>
                            + {commission.value}
                          </div>
                          <div className='timeline-badge'>
                            <i className='fa fa-genderless text-warning fs-1'></i>
                          </div>
                          <div className='fw-mormal timeline-content text-muted ps-3'>
                            {commission.change_date.toString().substr(6,2) + '/' + commission.change_date.toString().substr(4,2) + '/' + commission.change_date.toString().substr(0,4) + ' - ' + commission.partner.username}
                          </div>
                        </div>
                      )}

                    </div>
                  </>}


                </div>
                {/* end: Card Body */}
              </div>
            </div>

            <div className='col-xxl-4'>
              <div className={`card card-xxl-stretch`}>
                {/* begin::Header */}
                <div className='card-header align-items-center border-0 mt-4'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='fw-bolder mb-2 text-dark'>Anan System Tree</span>
                    <span className='text-danger fw-bold fs-3'>Manage your own system</span>
                  </h3>
                  <div className='card-toolbar'>
                    {/* begin::Menu */}
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    >
                      <KTSVG
                        path='/media/icons/duotone/Layout/Layout-4-blocks-2.svg'
                        className='svg-icon-2'
                      />
                    </button>
                    {/* end::Menu */}
                  </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body pt-5'>
                  {f1Arr.length < 1 && <>
                    <div className='fw-mormal timeline-content text-muted ps-3'>
                      No information
                    </div>
                  </>}

                  {f1Arr.length >= 1 && <div>
                    {f1Arr.map((f1) => <div key={f1.id} className='mb-5'>
                      <h3 className='text-primary'>&nbsp;&nbsp;+ {f1.username} - {f1.name}</h3>
                      {f1.f2 && <div>
                        {f1.f2.length >= 1 && <div>
                          {f1.f2.map((f2) =>
                            <div key={f2.id}>
                              <h4 className='text-warning'>&nbsp;&nbsp;&nbsp;&nbsp;- {f2.username} - {f2.name}</h4>

                              {f2.f3 && <div>
                                {f2.f3.length >= 1 && <div>
                                  {f2.f3.map((f3) =>
                                    <div key={f3.id}>
                                      <h4 className='text-success'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {f3.username} - {f3.name}</h4>

                                      {f3.f4 && <div>
                                        {f3.f4.length >= 1 && <div>
                                          {f3.f4.map((f4) =>
                                            <div key={f4.id}>
                                              <h4 className='text-danger'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {f4.username} - {f4.name}</h4>

                                              {f4.f5 && <div>
                                                {f4.f5.length >= 1 && <div>
                                                  {f4.f5.map((f5) =>
                                                    <div key={f5.id}>
                                                      <h4 className='text-success'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {f5.username} - {f5.name}</h4>

                                                      {f5.f6 && <div>
                                                        {f5.f6.length >= 1 && <div>
                                                          {f5.f6.map((f6) =>
                                                            <div key={f6.id}>
                                                              <h4 className='text-danger'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {f6.username} - {f6.name}</h4>



                                                            </div>
                                                          )}
                                                        </div>}
                                                      </div>}

                                                    </div>
                                                  )}
                                                </div>}
                                              </div>}

                                            </div>
                                          )}
                                        </div>}
                                      </div>}

                                    </div>
                                  )}
                                </div>}
                              </div>}

                            </div>
                          )}
                        </div>}
                      </div>}
                    </div>)}
                  </div>}
                </div>
                {/* end: Card Body */}
              </div>
            </div>

          </div>
          <div className="card-footer">
            <Link to='/manage-investor'>
              <button type="button" className="btn btn-primary mx-2">Quay về</button>
            </Link>
          </div>
        </form>
      </div>
    </>
  )
}

export {ViewInvestor}
