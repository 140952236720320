/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {login} from '../redux/AuthCRUD'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    // .email('Wrong email format')
    // .min(10, 'Số điện thoại phải là 10 ký tự')
    // .max(10, 'Số điện thoại phải là 10 ký tự')
    .required('Số điện thoại là bắt buộc'),
  password: Yup.string()
    // .min(6, 'Mật khẩu từ 6 đến 16 ký tự')
    // .max(16, 'Mật khẩu từ 6 đến 16 ký tự')
    .required('Mật khẩu là bắt buộc'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [visiblePassword, setVisiblePassword] = useState(false)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        login(values.email, values.password)
          .then((data) => {
            setLoading(false)
            const resData = data.data
            if (resData.error_code === 1) {
              const accessToken = resData.data.access_token
              dispatch(auth.actions.login(accessToken))
              // todo redirect
              window.location.reload()
            } else {
              setSubmitting(false)
              setStatus('Sai thông tin đăng nhập, vui lòng thử lại')
            }
          })
          .catch((err) => {
            setLoading(false)
            setSubmitting(false)
            setStatus('Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
          })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-3'>
        <h1 className='mb-3'>Đăng nhập Anan</h1>
      </div>
      <div className='text-center mb-10'>
        <h1 style={{ color: '#acadb2' }} className='mb-3'>Sign In to Anan</h1>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Tên đăng nhập / &nbsp;</label>
        <label style={{ color: '#acadb2' }} className='form-label fs-6 fw-bolder'>Username</label>
        <input
          placeholder=''
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Mật khẩu / &nbsp;</label>
            <label style={{ color: '#acadb2' }} className='form-label fw-bolder fs-6 mb-0'>Password</label>
            {/* end::Label */}
          </div>
          <label
            onClick={() => setVisiblePassword(!visiblePassword)}
            style={{ cursor: 'pointer', float: 'right', color: '#0d6de3', order: 2, fontWeight: 'bold' }}
          >
            {visiblePassword
              ?
              'Ẩn / Hide'
              :
              'Hiện / Show'
            }
          </label>
        </div>
        <input
          type={visiblePassword ? 'text' : 'password'}
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Đăng nhập / Sign in</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Vui lòng chờ...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <h6 style={{ color: '#acadb2', fontSize: '12px' }}>Nếu bạn đăng nhập lần đầu tiên, mật khẩu chính là số điện thoại / If you log in for the first time, the password is your phone number</h6>
        <h4 className='mt-5'>
          <span>Chưa có tài khoản Anan? </span>
          <Link to='/auth/registration'>
            <span>Đăng ký ngay</span>
          </Link>
        </h4>
      </div>
      {/* end::Action */}
    </form>
  )
}
