import React, {useEffect, useState} from 'react'
import { Button} from 'react-bootstrap-v5';
import { useSelector } from "react-redux";
import axios from "axios";
import {BACKEND_URL} from "../../modules/auth/redux/AuthCRUD";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from "react-router-dom";
import {KTSVG} from "../../../_metronic/helpers";

const Transfer = () => {
  const currentUser = useSelector(({auth}) => auth.user.user)
  console.log(currentUser)
  const [hasError, setHasError] = useState(true);
  const [isSendOtp, setIsSendOtp] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [receiverUsername, setReceiverUsername] = useState('');
  const [receiverName, setReceiverName] = useState('');
  const [transferValue, setTransferValue] = useState(0);
  const [otp, setOtp] = useState('');
  const history = useHistory()

  const withdrawHistoryArr = currentUser.withdraw ?? []
  let withdrawSum = 0;
  if (withdrawHistoryArr.length >= 1) {
    withdrawHistoryArr.forEach((withdraw) => {
      if (withdraw.is_done) {
        withdrawSum += withdraw.value
      }
    })
  }

  useEffect(() => {
      //axios.post(`${BACKEND_URL}/test/withdraw`, {}).then(() => {})
  }, [])

  useEffect(() => {
    if (transferValue && transferValue && otp) {
      setHasError(false)
    } else {
      setHasError(true)
    }
    //axios.post(`${BACKEND_URL}/test/withdraw`, {}).then(() => {})
  }, [walletAddress, transferValue, otp])

  return (
    <>
      <ToastContainer />
      <h1 className='text-danger fs-2 text-center'>Internal transfer USDT</h1>
      <div className='text-center'>
        <div className='fv-row mb-10 text-center'>
          <label className='form-label fs-2 fw-bolder text-primary'>Your receiver username</label>
          <input
            value={receiverUsername}
            onChange={(e) => setReceiverUsername(e.target.value)}
            className='form-control form-control-lg form-control-solid bg-secondary fs-5'
            type='text'
            autoComplete='off'
          />
        </div>
        <Button variant='primary' size="sm" onClick={() => {
          if (receiverUsername) {
            axios.post(`${BACKEND_URL}/investor/partner`, {
              partner: receiverUsername
            }).then(res => res.data)
              .then(resData => {
                console.log({resData})
                if (resData.error_code === 1) {
                  if (resData.data.user) {
                    let partnerName = resData.data.user.name ?? ''
                    if (partnerName) {
                      setReceiverName(partnerName)
                    }
                  }
                }
              })
          }
        }}>
          Check username
        </Button>
        {receiverName &&
          <p>You are going to send to <span className='text-danger'>{receiverName}</span></p>
        }

        <div className='fv-row mb-10 mt-10 text-center'>
          <label className='form-label fs-2 fw-bolder text-primary'>Value (Minimum transfer value is <span className='text-danger'>2</span> USDT - Your available commission is <span className='text-danger'>{currentUser.availableCommission}</span> USDT)</label>
          <input
            value={transferValue}
            onChange={(e) => setTransferValue(e.target.value)}
            className='form-control form-control-lg form-control-solid bg-secondary fs-3'
            type='number'
            autoComplete='off'
          />
        </div>
        {!isSendOtp &&
        <Button variant='primary' size="sm" onClick={() => {
          setIsSendOtp(true)
          axios.post(`${BACKEND_URL}/send-otp`, {
            email: currentUser.email,
          }).then(res => res.data)
            .then(resData => {
              if (resData.error_code === 1) {
                toast("Gửi Otp thành công", {})
              } else {
                toast("Gửi Otp không thành công, vui lòng liên hệ admin", {})
              }
            })
        }}>
          Send OTP
        </Button>
        }

        {isSendOtp &&
          <>
            <div className='fv-row mb-10 text-center'>
              <label className='form-label fs-6 fw-bolder text-primary fs-2'>OTP</label>
              <input
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className='form-control form-control-lg form-control-solid bg-secondary fs-3'
                type='text'
                autoComplete='off'
              />
            </div>

            {errorMsg &&
            <p className='text-danger fs-3'>{errorMsg}</p>
            }

            <div className='text-center'>
              <Button disabled={!hasError ? '' : 'disabled'} variant='success' size="sm" onClick={() => {
                setErrorMsg('')
                setHasError(true)
                axios.post(`${BACKEND_URL}/investor/tranfer-usdt`, {
                  access_token: currentUser.access_token,
                  receiver_username: receiverUsername,
                  otp: otp,
                  usdt_transfer_value: transferValue,
                }).then(res => res.data)
                  .then(resData => {
                    if (resData.error_code === 1) {
                      toast("Chuyển nội bộ thành công số tiền " + transferValue + " USDT", {
                        onClose: () => {
                          window.location.replace(window.location.origin + '/dashboard')
                        }
                      })
                    } else {
                      setErrorMsg(resData.message)
                      setHasError(false)
                    }
                  })
                  .catch(err => {
                    toast("Tạo lệnh rút tiền thất bại, vui lòng liên hệ admin", {})
                  })
              }}>
                Transfer
              </Button>
            </div>
          </>
        }
      </div>
    </>
  )
}

export {Transfer}
