/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from "axios";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, Link } from "react-router-dom";
import {BACKEND_URL} from "../../modules/auth/redux/AuthCRUD";

const ChangePassword = () => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(false)
  const [visiblePassword, setVisiblePassword] = useState(false)
  const [validateErrorMsgArr, setValidateErrorMsgArr] = useState([])

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [otp, setOtp] = useState('')

  const accessToken = useSelector(({auth}) => auth.accessToken, shallowEqual)
  const history = useHistory()

  const handleConfirm = () => {
    setIsLoading(true)
    if (newPassword !== confirmNewPassword) {
      setValidateErrorMsgArr(['New password and confirm new password are not the same'])
      setIsLoading(false)
      return false
    }
    setValidateErrorMsgArr([])
    axios.post(`${BACKEND_URL}/user/change-password`, {
      access_token: accessToken,
      password: oldPassword,
      new_password: newPassword,
      otp: otp,
    }).then(res => res.data)
      .then(resData => {
        setIsLoading(false)
        if (resData.error_code === 1) {
          toast("Password changed successfully", {
            onClose: () => {
              window.location.replace(window.location.origin + '/dashboard')
            }
          })
        } else {
          setValidateErrorMsgArr([resData.message ?? 'An error occurred, please contact the administrator'])
        }
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CHANGE_PASSWORD'})}</PageTitle>
      <ToastContainer />
      <div className="card card-custom">
        <div className="card-header">
          <h3 className="card-title">
            Change Password
          </h3>
        </div>
        <form>
          <div className="card-body">
            <div className="form-group d-none">
              <label>Old password <span className="text-danger">*</span></label>
              <input type="password" className="form-control" placeholder="Enter old password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)}/>
            </div>
            <div className="form-group mt-5">
              <label>New Password <span className="text-danger">*</span></label>
              <label
                onClick={() => setVisiblePassword(!visiblePassword)}
                style={{ cursor: 'pointer', float: 'right', color: 'blue' }}
              >
                {visiblePassword
                  ?
                  'Hide password'
                  :
                  'Show password'
                }
              </label>
              <input type={visiblePassword ? 'text' : 'password'} className="form-control" placeholder="Enter new password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/>
            </div>
            <div className="form-group mt-5">
              <label>Confirm new password <span className="text-danger">*</span></label>
              <input type={visiblePassword ? 'text' : 'password'} className="form-control" placeholder="Confirm new password" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)}/>
            </div>
            <div className="form-group mt-5">
              <label>Secret code <span className="text-danger">*</span></label>
              <input type="text" className="form-control" placeholder="Enter secret code" value={otp} onChange={(e) => setOtp(e.target.value)}/>
            </div>
            <div className="form-group mt-10">
              {validateErrorMsgArr.map((validateErrorMsg, index) => <p className="text-danger" key={index}>{validateErrorMsg}</p>)}
            </div>
          </div>
          <div className="card-footer">
            <button type="button" className="btn btn-primary mx-2 " disabled={isLoading} onClick={() => handleConfirm()}>Confirm</button>
            <Link to='dashboard'>
              <button type="button" className="btn btn-secondary mx-2">Cancel</button>
            </Link>
          </div>
        </form>
      </div>
    </>
  )
}

export {ChangePassword}
