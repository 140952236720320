/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from "axios";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, useParams, Link } from "react-router-dom";
import {KTSVG} from "../../../_metronic/helpers";
import {BACKEND_URL} from "../../modules/auth/redux/AuthCRUD";

const ViewShareholder = () => {
  const {id} = useParams()
  const intl = useIntl()
  const [provinces, setProvinces] = useState([])
  const [shareholderAccountQuantities, setShareholderAccountQuantities] = useState([])
  const [province, setProvince] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [validateErrorMsgArr, setValidateErrorMsgArr] = useState([])
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [provinceId, setProvinceId] = useState(1)
  const accessToken = useSelector(({auth}) => auth.accessToken, shallowEqual)
  const history = useHistory()

  useEffect(() => {
    axios.post(`${BACKEND_URL}/shareholder/read/${id}`, {
      access_token: accessToken
    }).then(data => data.data)
      .then(resData => {
        if (resData.error_code === 1) {
          const {shareholder} = resData.data
          setName(shareholder.name)
          setPhone(shareholder.phone)
          setAddress(shareholder.address)
          setProvince(shareholder.province.name)
          setShareholderAccountQuantities(shareholder.shareholder_account_quantity)
        } else {
          toast("Đã xảy ra lỗi, vui lòng liên hệ quản trị viên", {
            onClose: () => {
              history.push('/manage-shareholder')
            }
          })
        }
      })
    return () => {

    }
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.VIEW_SHAREHOLDER'})}</PageTitle>
      <ToastContainer />
      <div className="card card-custom">
        <div className="card-header">
          <h3 className="card-title">
            Thông tin nhà phân phối
          </h3>
        </div>
        <form>
          <div className="card-body">
            <div className="form-group">
              <label>Tên</label>
              <input type="text" className="form-control" value={name} readOnly={true}/>
            </div>
            <div className="form-group mt-5">
              <label>Số điện thoại</label>
              <input type="text" className="form-control" value={phone} readOnly={true}/>
            </div>
            <div className="form-group mt-5">
              <label>Tỉnh/thành phố</label>
              <input type="text" className="form-control" value={province} readOnly={true}/>
            </div>
            <div className="form-group mt-5">
              <label>Địa chỉ chi tiết</label>
              <input type="text" className="form-control" value={address} readOnly={true}/>
            </div>
            <div className='card-body py-3'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                  <tr className='fw-bolder text-muted'>
                    <th className='min-w-150px'>Số lượng</th>
                    <th className='min-w-100px text-end'>Ngày thêm</th>
                  </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                  {shareholderAccountQuantities.map((shareholderAccountQuantity) => {
                    const displayDate = shareholderAccountQuantity.change_date.toString().slice(6, 8) + '/' + shareholderAccountQuantity.change_date.toString().slice(4, 6) + '/' + shareholderAccountQuantity.change_date.toString().slice(0, 4)
                    return (
                      <tr key={shareholderAccountQuantity.id}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                {shareholderAccountQuantity.account_quantity ?? ''}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className='text-end'>
                          <div className='d-flex flex-column w-100 me-2'>
                            <div className='d-flex flex-stack mb-2'>
                              <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                {displayDate ?? ''}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
              {/* end::Table container */}
            </div>
          </div>
          <div className="card-footer">
            <Link to={'/manage-shareholder'}>
              <button type="button" className="btn btn-primary mx-2">Quay về</button>
            </Link>
          </div>
        </form>
      </div>
    </>
  )
}

export {ViewShareholder}
