import React, {useEffect, useState} from 'react'
import { Button, InputGroup, Form, Modal } from 'react-bootstrap-v5';
import {toAbsoluteUrl} from "../../../_metronic/helpers";

const Shopping = () => {
  const [option, setOption] = useState('option1');
  const [show, setShow] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <h1 style={{ color: '#70cb34' }}>Chọn gói giải pháp Anbi</h1>

      <div onClick={() => setOption('option1')} style={{ display: 'flex', backgroundColor: '#e7f3fd', padding: '15px 20px', borderRadius: '20px', margin: '20px 0px' }}>
        <div style={{ marginRight: '20px', paddingTop: '5px' }}>
          <Form.Check type="radio" name='package' value='option1' checked={ option === 'option1' } onChange={(e) => setOption(e.target.value)}/>
        </div>
        <div>
          <p style={{ fontSize: '20px', color: '#66a92f' }}>Gói khởi động</p>
          <p>Tặng cổ phần Anbi</p>
          <p>Giá trị giao dịch chỉ <span className='text-primary fs-3 px-1'>25</span> USDT</p>
        </div>
      </div>

        <div onClick={() => setOption('option2')} style={{ display: 'flex', backgroundColor: '#e7f3fd', padding: '15px 20px', borderRadius: '20px', margin: '20px 0px' }}>
        <div style={{ marginRight: '20px', paddingTop: '5px' }}>
          <Form.Check type="radio" name='package' value='option2' checked={ option === 'option2' } onChange={(e) => setOption(e.target.value)}/>
        </div>
        <div>
          <p style={{ fontSize: '20px', color: '#66a92f' }}>Gói cá nhân</p>
          <p>Tặng cổ phần Anbi</p>
          <p>Giá mua ưu đãi tài khoản Scan Face Anbi sale 20%</p>
          <p>Giá trị giao dịch từ <span className='text-primary fs-3 px-1'>25</span> USDT đến <span className='text-primary fs-3 px-1'>500</span> USDT</p>
        </div>
      </div>

      <div onClick={() => setOption('option3')} style={{ display: 'flex', backgroundColor: '#e7f3fd', padding: '15px 20px', borderRadius: '20px' }}>
        <div style={{ marginRight: '20px', paddingTop: '5px' }}>
          <Form.Check type="radio" name='package' value='option3' checked={ option === 'option3' } onChange={(e) => setOption(e.target.value)}/>
        </div>
        <div>
          <p style={{ fontSize: '20px', color: '#66a92f' }}>Gói gia đình</p>
          <p>Tặng cổ phần Anbi</p>
          <p>Giá mua ưu đãi tài khoản Scan Face Anbi sale 30%</p>
          <p>Giá trị giao dịch từ <span className='text-primary fs-3 px-1'>500</span> USDT đến <span className='text-primary fs-3 px-1'>5000</span> USDT</p>
        </div>
      </div>

      <div className='my-5 text-center'>
        <Button variant="primary" onClick={handleShow}>Xác nhận thanh toán</Button>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <span className='text-primary fs-3'>Thanh toán USDT (BEP20) ví TRUST WALLET</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className='text-center'>
              { option === 'option1' && <p className='fs-4'>Bạn vui lòng chuyển <span className='text-primary fs-3'>25</span> USDT_BEP20</p> }
              { option === 'option2' && <p className='fs-4'>Bạn vui lòng chuyển từ <span className='text-primary fs-3'>25</span> đến <span className='text-primary fs-3'>500</span> USDT_BEP20</p> }
              { option === 'option3' && <p className='fs-4'>Bạn vui lòng chuyển từ <span className='text-primary fs-3'>500</span> đến <span className='text-primary fs-3'>5000</span> USDT_BEP20</p> }
            </div>
            <hr/>
            <div className='text-center'>
              <p>Quét mã QR bằng ví USDT (BEP20) của bạn</p>
              <img
                alt='Logo'
                className='logo w-100'
                src={toAbsoluteUrl('/media/logos/wallet-address.jpg')}
              />
            </div>
            <hr/>
            <div className='text-center'>
              <p>Gửi USDT (BEP20) của bạn đến địa chỉ này</p>
              <p className='text-primary fs-5'>0xa5a115D1F5e2000660D4c4be7ee6E13C57F11fD8</p>
              <Button variant={!isCopied ? 'primary' : 'success'} size="sm" onClick={() => {
                setIsCopied(true)
                navigator.clipboard.writeText('0xa5a115D1F5e2000660D4c4be7ee6E13C57F11fD8')
              }}>
                {!isCopied ? 'Sao chép địa chỉ' : 'Đã sao chép'}
              </Button>
              <p className='mt-5'>Vui lòng đảm bảo bạn chỉ gửi USDT trên mạng BEP20. Gửi bất kỳ tài sản kỹ thuật số nào khác, bao gồm USDT trên một mạng khác có thể dẫn đến mất khoản tiền gửi của bạn vĩnh viễn.</p>
              <p>Để đảm bảo an toàn, hãy hoàn tất việc gửi USDT (BEP20) bằng ví cá nhân của bạn chứ không phải trực tiếp từ sàn giao dịch.</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", justifyContent: "space-between"}}>
          <Button style={{ width: '45%' }} variant="secondary" onClick={handleClose}>
            Hủy bỏ
          </Button>
          <Button style={{ width: '50%' }} variant="success" onClick={handleClose}>
            Tôi đã chuyển USDT
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {Shopping}
