/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from "axios";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, useParams, Link } from "react-router-dom";
import {KTSVG} from "../../../_metronic/helpers";
import {BACKEND_URL} from "../../modules/auth/redux/AuthCRUD";

const OutsideInvestor = () => {
  const {id} = useParams()
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [walletAddress, setWalletAddress] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [phone, setPhone] = useState('')
  const [accountQuantity, setAccountQuantity] = useState(0)
  const [usdt, setUsdt] = useState(0)
  const [stockQuantity, setStockQuantity] = useState(0)
  const [note, setNote] = useState('')
  const [address, setAddress] = useState('')
  const [provinceId, setProvinceId] = useState(1)
  const accessToken = useSelector(({auth}) => auth.accessToken, shallowEqual)
  const history = useHistory()
  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)

  const handleSave = () => {
    try {
      setErrMsg('')

      if (!usdt || parseInt(usdt) < 1) {
        setErrMsg('Please input usdt')
        return false
      }

      setIsLoading(true)

      axios.post(`${BACKEND_URL}/investor/outside/${id}`, {
        access_token: accessToken,
        usdt: usdt,
        note: note,
      }).then(data => data.data)
        .then(resData => {
          setIsLoading(false)
          if (resData.error_code === 1) {
            toast("Chi ngoài nhà đầu tư thành công", {
              onClose: () => {
                history.push('/manage-investor')
              }
            })
          } else {
            setErrMsg(resData.message ?? 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
          }
        })
    }
    catch (err) {
      setIsLoading(false)
      toast("Đã xảy ra lỗi, vui lòng liên hệ quản trị viên")
    }
  }

  useEffect(() => {
    axios.post(`${BACKEND_URL}/investor/view/${id}`, {
      access_token: accessToken
    }).then(data => data.data)
      .then(resData => {
        if (resData.error_code === 1) {
          const {investor} = resData.data
          setName(investor.name)
          setPhone(investor.phone)
          setEmail(investor.email ?? '')
          setWalletAddress(investor.wallet_address ?? '')
        } else {
          toast("Đã xảy ra lỗi, vui lòng liên hệ quản trị viên", {
            onClose: () => {
              history.push('/manage-investor')
            }
          })
        }
      })
    return () => {

    }
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.OUTSIDE_INVESTOR'})}</PageTitle>
      <ToastContainer />
      <div className="card card-custom">
        <div className="card-header">
          <h3 className="card-title">
            Chi ngoài cho nhà đầu tư
          </h3>
        </div>
        <form>
          <div className="card-body">
            <div className="form-group">
              <label>Họ và tên</label>
              <input type="text" className="form-control bg-secondary" value={name} readOnly={true} disabled={true}/>
            </div>
            <div className="form-group mt-5">
              <label>Số điện thoại</label>
              <input type="text" className="form-control bg-secondary" value={phone} readOnly={true} disabled={true}/>
            </div>
            <div className="form-group mt-5">
              <label>Email</label>
              <input type="text" className="form-control bg-secondary" value={email} readOnly={true} disabled={true}/>
            </div>
            <div className="form-group mt-5">
              <label>Địa chỉ ví</label>
              <input type="text" className="form-control bg-secondary" value={walletAddress} readOnly={true} disabled={true}/>
            </div>
            <div className="form-group mt-5">
              <label>Số lượng USDT chi ngoài <span className='text-danger'>*</span></label>
              <input type="text" className="form-control" value={usdt} onChange={e => setUsdt(e.target.value)}/>
            </div>
            <div className="form-group mt-5">
              <label>Ghi chú cho khoản chi ngoài</label>
              <input type="text" className="form-control" value={note} onChange={e => setNote(e.target.value)}/>
            </div>
            <div className="form-group mt-10">
              <p className="text-danger">{errMsg ?? ''}</p>
            </div>
          </div>
          <div className="card-footer">
            <button type="button" className="btn btn-primary mx-2" disabled={isLoading} onClick={() => {handleSave()}}>Lưu</button>
            <Link to='/manage-investor'>
              <button type="button" className="btn btn-secondary mx-2">Quay về</button>
            </Link>
          </div>
        </form>
      </div>
    </>
  )
}

export {OutsideInvestor}
