import React, {useEffect, useState} from 'react'
import { Button} from 'react-bootstrap-v5';
import { useSelector } from "react-redux";
import axios from "axios";
import {BACKEND_URL} from "../../modules/auth/redux/AuthCRUD";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from "react-router-dom";
import {KTSVG} from "../../../_metronic/helpers";

const Withdraw = () => {
  const currentUser = useSelector(({auth}) => auth.user.user)
  console.log(currentUser)
  const [hasError, setHasError] = useState(true);
  const [isSendOtp, setIsSendOtp] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [withdrawValue, setWithdrawValue] = useState(0);
  const [otp, setOtp] = useState('');
  const history = useHistory()

  const withdrawHistoryArr = currentUser.withdraw ?? []
  let withdrawSum = 0;
  if (withdrawHistoryArr.length >= 1) {
    withdrawHistoryArr.forEach((withdraw) => {
      if (withdraw.is_done) {
        withdrawSum += withdraw.value
      }
    })
  }

  useEffect(() => {
      //axios.post(`${BACKEND_URL}/test/withdraw`, {}).then(() => {})
  }, [])

  useEffect(() => {
    if (withdrawValue && withdrawValue && otp) {
      setHasError(false)
    } else {
      setHasError(true)
    }
    //axios.post(`${BACKEND_URL}/test/withdraw`, {}).then(() => {})
  }, [walletAddress, withdrawValue, otp])

  return (
    <>
      <ToastContainer />
      <h1 className='text-primary fs-2 text-center'>Withdraw USDT (BEP20) - TRUST WALLET</h1>
      <div className='text-center'>
        <p className='mt-5 fs-4'>Please ensure you only withdraw USDT (BEP20) on the BEP20 network. Sending any other digital asset, including USDT on a different network may result in a permanent loss of your withdraw.</p>
        <p className='fs-4'>For your safety complete your USDT (BEP20) withdraw using your personal wallet and not directly from an exchange.</p>
      </div>
      <div className='text-center'>
        <div className='fv-row mb-10 text-center'>
          <label className='form-label fs-2 fw-bolder text-primary'>Your wallet address</label>
          <input
            value={walletAddress}
            onChange={(e) => setWalletAddress(e.target.value)}
            className='form-control form-control-lg form-control-solid bg-secondary fs-5'
            type='text'
            autoComplete='off'
          />
        </div>
        <div className='fv-row mb-10 text-center'>
          <label className='form-label fs-2 fw-bolder text-primary'>Value (Minimum withdrawal value is <span className='text-danger'>2</span> USDT - Your available commission is <span className='text-danger'>{currentUser.availableCommission}</span> USDT)</label>
          <input
            value={withdrawValue}
            onChange={(e) => setWithdrawValue(e.target.value)}
            className='form-control form-control-lg form-control-solid bg-secondary fs-3'
            type='number'
            autoComplete='off'
          />
        </div>
        {!isSendOtp &&
        <Button variant='primary' size="sm" onClick={() => {
          setIsSendOtp(true)
          axios.post(`${BACKEND_URL}/send-otp`, {
            email: currentUser.email,
          }).then(res => res.data)
            .then(resData => {
              if (resData.error_code === 1) {
                toast("Gửi Otp thành công", {})
              } else {
                toast("Gửi Otp không thành công, vui lòng liên hệ admin", {})
              }
            })
        }}>
          Send OTP
        </Button>
        }

        {isSendOtp &&
          <>
            <div className='fv-row mb-10 text-center'>
              <label className='form-label fs-6 fw-bolder text-primary fs-2'>OTP</label>
              <input
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className='form-control form-control-lg form-control-solid bg-secondary fs-3'
                type='text'
                autoComplete='off'
              />
            </div>

            {errorMsg &&
            <p className='text-danger fs-3'>{errorMsg}</p>
            }

            <div className='text-center'>
              <Button disabled={!hasError ? '' : 'disabled'} variant='success' size="sm" onClick={() => {
                setErrorMsg('')
                setHasError(true)
                axios.post(`${BACKEND_URL}/investor/withdraw`, {
                  access_token: currentUser.access_token,
                  wallet_address: walletAddress,
                  otp: otp,
                  value: withdrawValue,
                }).then(res => res.data)
                  .then(resData => {
                    if (resData.error_code === 1) {
                      toast("Tạo lệnh rút tiền thành công số tiền " + withdrawValue + " USDT", {
                        onClose: () => {
                          window.location.replace(window.location.origin + '/dashboard')
                        }
                      })
                    } else {
                      setErrorMsg(resData.message)
                      setHasError(false)
                    }
                  })
                  .catch(err => {
                    toast("Tạo lệnh rút tiền thất bại, vui lòng liên hệ admin", {})
                  })
              }}>
                Withdraw
              </Button>
            </div>
          </>

        }

      </div>
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-4'>
          <div className={`card card-xxl-stretch`}>
            {/* begin::Header */}
            <div className='card-header align-items-center border-0 mt-4'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='fw-bolder mb-2 text-dark'>Anan Withdraw History</span>
                <span className='text-danger fw-bold fs-3'>Received {withdrawSum} USDT</span>
              </h3>
              <div className='card-toolbar'>
                {/* begin::Menu */}
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <KTSVG
                    path='/media/icons/duotone/Layout/Layout-4-blocks-2.svg'
                    className='svg-icon-2'
                  />
                </button>
                {/* end::Menu */}
              </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body pt-5'>
              {withdrawHistoryArr.length < 1 && <>
                <div className='fw-mormal timeline-content text-muted ps-3'>
                  No information
                </div>
              </>}

              {withdrawHistoryArr.length >= 1 && <>
                <div className='timeline-label'>

                  {withdrawHistoryArr.map((withdraw) =>
                    <div className='timeline-item' key={withdraw.id}>
                      <div className='timeline-label fw-bolder text-gray-800 fs-6 w-60px'>
                        - {withdraw.value}
                      </div>
                      <div className='timeline-badge'>
                        <i className='fa fa-genderless text-warning fs-1'></i>
                      </div>
                      <div className='fw-mormal timeline-content text-muted ps-3'>
                        {withdraw.change_date.toString().substr(6,2) + '/' + withdraw.change_date.toString().substr(4,2) + '/' + withdraw.change_date.toString().substr(0,4) + ' - '}
                        {withdraw.is_done ? <span className='text-success'>Success</span> : <span className='text-warning'>Pending</span>}
                        <br/>
                        {withdraw.wallet_address}
                      </div>
                    </div>
                  )}

                </div>
              </>}
            </div>
            {/* end: Card Body */}
          </div>
        </div>
      </div>
    </>
  )
}

export {Withdraw}
