/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from "axios";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import {BACKEND_URL} from "../../modules/auth/redux/AuthCRUD";

const CreateShareholder = () => {
  const intl = useIntl()
  const [provinces, setProvinces] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [validateErrorMsgArr, setValidateErrorMsgArr] = useState([])
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [provinceId, setProvinceId] = useState(1)
  const accessToken = useSelector(({auth}) => auth.accessToken, shallowEqual)
  const history = useHistory()


  const validData = () => {
    // if (phone.length !== 10) {
    //   return false
    //   // setValidateErrorMsgArr([...validateErrorMsgArr, 'Số điện thoại phải có 10 ký tự'])
    // }
    // if (name.length < 6 || name.length > 16) {
    //   return false
    //   // setValidateErrorMsgArr([...validateErrorMsgArr, 'Tên phải có 6 đến 16 ký tự'])
    // }
    return true
  }

  const handleConfirm = () => {
    setIsLoading(true)
    // if (phone.length !== 10) {
    //   setValidateErrorMsgArr(['Số điện thoại phải có 10 ký tự'])
    //   setIsLoading(false)
    //   return false
    // }
    // if (name.length < 6 || name.length > 16) {
    //   setValidateErrorMsgArr(['Tên phải có 6 đến 16 ký tự'])
    //   setIsLoading(false)
    //   return false
    // }
    setValidateErrorMsgArr([])
    axios.post(`${BACKEND_URL}/shareholder/create`, {
      name: name,
      phone: phone,
      address: address,
      province_id: provinceId,
      access_token: accessToken,
    }).then(res => res.data)
      .then(resData => {
        setIsLoading(false)
        if (resData.error_code === 1) {
          toast("Tạo mới nhà phân phối thành công", {
            onClose: () => {
              history.push('/manage-shareholder')
            }
          })
        } else {
          setValidateErrorMsgArr([resData.message ?? 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên'])
        }
      })
  }

  useEffect(() => {
    axios.get(`${BACKEND_URL}/provinces`).then(resData => {
      if (resData.data.error_code === 1) {
        setProvinces(resData.data.data.provinces)
      }
    })
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CREATE_SHAREHOLDER'})}</PageTitle>
      <ToastContainer />
      <div className="card card-custom">
        <div className="card-header">
          <h3 className="card-title">
            Thêm mới nhà phân phối
          </h3>
        </div>
        <form>
          <div className="card-body">
            <div className="form-group">
              <label>Tên <span className="text-danger">*</span></label>
              <input type="text" className="form-control" placeholder="Nhập tên" value={name} onChange={(e) => setName(e.target.value)}/>
            </div>
            <div className="form-group mt-5">
              <label>Số điện thoại <span className="text-danger">*</span></label>
              <input type="text" className="form-control" placeholder="Nhập số điện thoại" value={phone} onChange={(e) => setPhone(e.target.value)}/>
            </div>
            <div className="form-group mt-5">
              <label htmlFor="pickProvince">Chọn tỉnh/thành phố <span className="text-danger">*</span></label>
              <select className="form-control" id="pickProvince" value={provinceId} onChange={(e) => setProvinceId(e.target.value)}>
                {provinces.map(province =>
                  <option key={province.id} value={province.id}>{province.name}</option>
                )}
              </select>
            </div>
            <div className="form-group mt-5">
              <label>Địa chỉ chi tiết</label>
              <input type="text" className="form-control" placeholder="Nhập địa chỉ" value={address} onChange={(e) => setAddress(e.target.value)}/>
            </div>
            <div className="form-group mt-10">
              {validateErrorMsgArr.map((validateErrorMsg, index) => <p className="text-danger" key={index}>{validateErrorMsg}</p>)}
            </div>
          </div>
          <div className="card-footer">
            <button type="button" className="btn btn-primary mx-2 " disabled={isLoading} onClick={() => handleConfirm()}>Xác nhận</button>
            <button type="reset" className="btn btn-secondary mx-2">Hủy bỏ</button>
          </div>
        </form>
      </div>
    </>
  )
}

export {CreateShareholder}
