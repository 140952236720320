/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import axios from "axios";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {useHistory, Link} from 'react-router-dom'
import {BACKEND_URL} from "../../modules/auth/redux/AuthCRUD";
import moment from 'moment'

type Props = {
  className: string
}

const TableCustomer: React.FC<Props> = ({className}) => {
  const WIDTH = window.innerWidth
  const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)
  const authInfo: any = useSelector<RootState>(({auth}) => auth, shallowEqual)
  const authRoleID = authInfo.user.user.auth_role_id
  const [customers, setCustomers] = useState([])
  const [displayCustomers, setDisplayCustomers] = useState([])
  const [timeFilter, setTimeFilter] = useState('all')
  const [searchKeyword, setSearchKeyword] = useState('')
  const [itemPerPage, setItemPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState([1])
  const history = useHistory()

  useEffect(() => {
    // lọc theo từ khóa
    let displayCustomers: any = []
    if (searchKeyword) {
      customers.forEach((customer) => {
        // moi customer la mot object
        let isPush = false;
        Object.entries(customer).forEach(([key, value]) => {
          if (value && String(value).normalize().toLowerCase().includes(searchKeyword.normalize().toLowerCase()) && !isPush) {
            displayCustomers.push(customer)
            isPush = true;
          }
        });
      })
    } else {
      displayCustomers = [...customers];
    }

    let displayCustomersWithTime: any = []
    // lọc theo ngày today, thisWeek, thisMonth, all
    switch (timeFilter) {
      case 'today':
        let today = moment().format('YYYYMMDD')
        displayCustomers.forEach((displayCustomer: any) => {
          if (displayCustomer.created_date.toString() === today.toString()) {
            displayCustomersWithTime.push(displayCustomer)
          }
        })
        break;
      case 'thisWeek':
        let firstDayOfWeek = moment().clone().weekday(1).format('YYYYMMDD');
        let lastDayOfWeek = moment().clone().weekday(7).format('YYYYMMDD');
        displayCustomers.forEach((displayCustomer: any) => {
          if (parseInt(displayCustomer.created_date.toString()) >= parseInt(firstDayOfWeek.toString())
            && parseInt(displayCustomer.created_date.toString()) <= parseInt(lastDayOfWeek.toString())) {
            displayCustomersWithTime.push(displayCustomer)
          }
        })
        break;
      case 'thisMonth':
        let firstDayOfMonth = moment().startOf('month').format('YYYYMMDD');
        let lastDayOfMonth = moment().endOf('month').format('YYYYMMDD');
        displayCustomers.forEach((displayCustomer: any) => {
          if (parseInt(displayCustomer.created_date.toString()) >= parseInt(firstDayOfMonth.toString())
            && parseInt(displayCustomer.created_date.toString()) <= parseInt(lastDayOfMonth.toString())) {
            displayCustomersWithTime.push(displayCustomer)
          }
        })
        break;
      case 'all':
        displayCustomersWithTime = [...displayCustomers]
        break;
    }

    // phân trang
    if (displayCustomersWithTime.length > 0) {
      let pageQuantity = Math.ceil(displayCustomersWithTime.length / itemPerPage)
      let paginationArr = []
      for (let i = 1; i <= pageQuantity; i++) {
        paginationArr.push(i)
      }
      setPagination(paginationArr)

      let displayCustomersWithPage: any = []
      displayCustomersWithTime.forEach((displayCustomer: any, index: any) => {
        let order = index + 1
        let firstItem = (currentPage - 1) * itemPerPage + 1;
        let lastItem = currentPage * itemPerPage;
        if (order >= firstItem && order <= lastItem) {
          displayCustomersWithPage.push(displayCustomer)
        }
      })

      setDisplayCustomers(displayCustomersWithPage)
    } else {
      setDisplayCustomers(displayCustomersWithTime)
    }

  }, [searchKeyword, timeFilter, customers, itemPerPage, currentPage])

  useEffect(() => {
    axios.post(authRoleID === 5 ? `${BACKEND_URL}/customer/read-by-sale` : `${BACKEND_URL}/customer/read`, {
      access_token: accessToken
    }).then(data => data.data)
      .then(resData => {
        if (resData.error_code === 1) {
          //setCustomers(resData.data.customers)
          // chỉ lấy người dùng đang hoạt động
          let customerArr: any = []
          if (resData.data.customers.length > 0) {
            resData.data.customers.map((customer: any) => {
              if (customer.is_active) {
                customerArr.push(customer)
              }
            })
          }
          setCustomers(customerArr)
        }
      })
    return () => {}
  }, [])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Danh sách người dùng</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Thêm người dùng mới'
        >
          <a
            style={{ display: 'none' }}
            href='#'
            className='btn btn-sm btn-light-primary'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_invite_friends'
            onClick={(e) => {
              e.preventDefault()
              history.push('/create-customer')
            }}
          >
            <KTSVG path='media/icons/duotone/Communication/Add-user.svg' className='svg-icon-3' />
            Thêm người dùng mới
          </a>

          <input
            style={{ width: WIDTH > 560 ? '30%' : '100%' }}
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            type="text" className="form-control me-2 mt-2 mb-2" placeholder="Từ khóa"
          />

          <button
            onClick={() => setTimeFilter('today')}
            type="button" className={timeFilter === 'today' ? 'btn btn-primary me-2 mt-2 mb-2' : 'btn btn-secondary me-2 mt-2 mb-2'}>Hôm nay</button>
          <button
            onClick={() => setTimeFilter('thisWeek')}
            type="button" className={timeFilter === 'thisWeek' ? 'btn btn-primary me-2 mt-2 mb-2' : 'btn btn-secondary me-2 mt-2 mb-2'}>Tuần này</button>
          <button
            onClick={() => setTimeFilter('thisMonth')}
            type="button" className={timeFilter === 'thisMonth' ? 'btn btn-primary me-2 mt-2 mb-2' : 'btn btn-secondary me-2 mt-2 mb-2'}>Tháng này</button>
          <button
            onClick={() => setTimeFilter('all')}
            type="button" className={timeFilter === 'all' ? 'btn btn-primary me-2 mt-2 mb-2' : 'btn btn-secondary me-2 mt-2 mb-2'}>Tất cả</button>

        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
            <tr className='fw-bolder text-muted'>
              <th className='min-w-50px'>STT</th>
              <th className='min-w-150px'>Tên người dùng</th>
              <th className='min-w-140px'>Số điện thoại</th>
              <th className='min-w-120px'>Mã quét tính cách</th>
              <th className='min-w-120px'>Trạng thái quét tính cách</th>
              <th className='min-w-120px'>Ngày tạo</th>
            </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {displayCustomers.length > 0 && displayCustomers.map((customer: any, index: any) => {
              if (customer.is_active) {
                return (
                  <tr key={customer.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <span className='text-dark fw-bolder text-hover-primary fs-6'>
                            {(currentPage - 1) * itemPerPage + index + 1}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <span className='text-dark fw-bolder text-hover-primary fs-6'>
                            {customer.name ?? ''}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                        {customer.phone ?? ''}
                      </span>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <span className='text-dark fw-bolder text-hover-primary fs-6'>
                            {customer.scan_code ?? ''}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <span className='text-dark fw-bolder text-hover-primary fs-6'>
                            {customer.is_scan === 1 ? 'Đã quét' : 'Chưa quét'}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <span className='text-dark fw-bolder text-hover-primary fs-6'>
                            {customer.created_at && <>
                              {customer.created_at.substr(8,2) + '/' + customer.created_at.substr(5,2) + '/' + customer.created_at.substr(0,4)}
                            </>}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              }
            })}
            {displayCustomers.length === 0 &&
            <>
              <tr>
                <td>Không có dữ liệu, vui lòng thử lại</td>
              </tr>
            </>}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
          <div className='my-2' style={{ display: 'flex', justifyContent: 'space-between' }}>
            <select
              onChange={(e) => {setItemPerPage(parseInt(e.target.value))}}
              className="form-control selectpicker w-60px text-center">
              <option className='text-center'>10</option>
              <option className='text-center'>25</option>
              <option className='text-center'>50</option>
              <option className='text-center'>100</option>
            </select>
            <div className="d-flex flex-wrap">
              {pagination.map((page) =>
                <button
                  key={page}
                  onClick={() => setCurrentPage(page)}
                  type='button'
                  className={currentPage === page ? "btn btn-icon btn-sm border-0 btn-light btn-hover-primary active mr-2 my-1" : "btn btn-icon btn-sm border-0 btn-light mr-2 my-1"}>
                  {page}
                </button>)
              }
            </div>
          </div>
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TableCustomer}
