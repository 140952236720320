import React, {useEffect, useState} from 'react'
import { Button} from 'react-bootstrap-v5';
import { useSelector } from "react-redux";
import axios from "axios";
import {BACKEND_URL} from "../../modules/auth/redux/AuthCRUD";
import {
  StatisticsWidget5,
} from '../../../_metronic/partials/widgets/statistics/StatisticsWidget5'

const Deposit = () => {
  const currentUser = useSelector(({auth}) => auth.user.user)
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
      //axios.post(`${BACKEND_URL}/test/deposit`, {}).then(() => {})
  }, [])

  return (
    <>
      <h1 className='text-primary fs-2 text-center'>Deposit USDT (BEP20) - TRUST WALLET</h1>

      <div className="container">
        <div className="row">
          <div className='col-12 col-sm-4 mt-5'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='/media/icons/duotone/Communication/Group.svg'
              color='success'
              iconColor='white'
              title='$ 30 USDT'
              description='Join the System'
            />
          </div>
          <div className='col-12 col-sm-4 mt-5'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='/media/icons/duotone/Communication/Group.svg'
              color='warning'
              iconColor='white'
              title='$ 60 - 500 USDT'
              description='Anbi price 60 $ + Anan Stock'
            />
          </div>
          <div className='col-12 col-sm-4 mt-5'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='/media/icons/duotone/Communication/Group.svg'
              color='primary'
              iconColor='white'
              title='$ 500 - 5000 USDT'
              description='Anbi price 55 $ + Anan Stock'
            />
          </div>
          <div className='col-12 col-sm-4 mt-5'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='/media/icons/duotone/Communication/Group.svg'
              color='warning'
              iconColor='white'
              title='$ 5000 - 20000 USDT'
              description='Anbi price 50 $ + Anan Stock'
            />
          </div>
          <div className='col-12 col-sm-4 mt-5'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='/media/icons/duotone/Communication/Group.svg'
              color='info'
              iconColor='white'
              title='$ 20000 - 200000 USDT'
              description='Anbi price 48 $ + Anan Stock'
            />
          </div>
          <div className='col-12 col-sm-4 mt-5'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='/media/icons/duotone/Communication/Group.svg'
              color='danger'
              iconColor='white'
              title='$ 200000 - 1M USDT'
              description='Anbi price 45 $ + Anan Stock'
            />
          </div>
        </div>
      </div>

      <div>
        {currentUser.wallet_qr_link && <>
          <div className='text-center fs-3 mt-10'>
            <p>Scan Qr Code With Your USDT (BEP20)</p>
            <div className="col-12 col-sm-5 mx-auto">
              <img
                alt='QR'
                className='logo w-100'
                src={currentUser.wallet_qr_link}
              />
            </div>
          </div>
          <hr/>
        </>}

        {currentUser.wallet_address && <>
          <div className='text-center fs-3'>
            <p>Send Your USDT (BEP20) to This Address</p>
            <p className='text-primary fs-6'>{currentUser.wallet_address}</p>
            <Button variant={!isCopied ? 'primary' : 'success'} size="sm" onClick={() => {
                //axios.post(`${BACKEND_URL}/test/copy-deposit`, {}).then(() => {})
              setIsCopied(true)
              navigator.clipboard.writeText(currentUser.wallet_address)
            }}>
              {!isCopied ? 'Copy Address' : 'Copied'}
            </Button>
            <p className='mt-5'>Please ensure you only send USDT (BEP20) on the BEP20 network. Sending any other digital asset, including USDT on a different network may result in a permanent loss of your deposit.</p>
            <p>For your safety complete your USDT (BEP20) deposit using your personal wallet and not directly from an exchange.</p>
          </div>
        </>}
      </div>

      <div className="container">
        <div className="row justify-content-around">
          <div className='col-12 col-sm-4 mt-5'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='/media/icons/duotone/Communication/Group.svg'
              color='success'
              iconColor='white'
              title='$ 10 USDT'
              description='Membership commission benefits 1 month'
            />
          </div>
          <div className='col-12 col-sm-4 mt-5'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='/media/icons/duotone/Communication/Group.svg'
              color='primary'
              iconColor='white'
              title='$ 100 USDT'
              description='Membership commission benefits 12 months'
            />
          </div>
        </div>
      </div>

    </>
  )
}

export {Deposit}
