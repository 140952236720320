/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import { MixedWidget2 } from '../../../_metronic/partials/widgets'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import * as auth from "../../modules/auth/redux/AuthRedux";
import {KTSVG} from "../../../_metronic/helpers";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap-v5";
import moment from "moment";

const DashboardPage = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)
  console.log({currentUser})
  const depositHistoryArr = currentUser.user.deposit_history ?? []
  const [isCopied, setIsCopied] = useState(false);
  const accountHistoryArr = currentUser.user.investor_account_quantity ?? []
  const stockHistoryArr = currentUser.user.investor_stock_quantity ?? []
  const commissionArr = currentUser.user.commission ?? []
  const f1Arr = currentUser.user.f1 ?? []
  const withdrawHistoryArr = currentUser.user.withdraw ?? []
  const roleId = currentUser.user.role_id;
  let depositSum = 0;
  let accountSum = 0;
  let stockDepositSum = 0;
  let stockCommissionSum = 0;
  let commissionSum = 0;
  let withdrawSum = 0;
  if (depositHistoryArr.length >= 1) {
    depositHistoryArr.forEach((depositHistory) => depositSum += depositHistory.usdt)
  }
  if (accountHistoryArr.length >= 1) {
    accountHistoryArr.forEach((accountHistory) => accountSum += accountHistory.account_quantity)
  }
  if (stockHistoryArr.length >= 1) {
    stockHistoryArr.forEach((stockHistory) => {
      if (stockHistory.partner_id) {
        stockCommissionSum += stockHistory.stock_quantity
      } else {
        stockDepositSum += stockHistory.stock_quantity
      }
    })
  }
  if (commissionArr.length >= 1) {
    commissionArr.forEach((commission) => commissionSum += commission.value)
  }
  if (withdrawHistoryArr.length >= 1) {
    withdrawHistoryArr.forEach((withdraw) => {
      if (withdraw.is_done) {
        withdrawSum += withdraw.value
      }
    })
  }

  const [quantityDateArr, setQuantityDateArr] = useState([])


  useEffect(() => {
    // get current agency
    if (currentUser.user.role_id === 5) {
      let agencyAccQuantityArr = currentUser.user.account_quantity_arr
      let quantityDateArr = []
      if (agencyAccQuantityArr.length > 0) {
        agencyAccQuantityArr.forEach((accQuantity) => {
          if (accQuantity.account_quantity < 0) {
            quantityDateArr.push(accQuantity.change_date)
          }
        })
      }
      let counter = {}
      quantityDateArr.forEach((quantityDate) => {
        if (counter[quantityDate]) {
          counter[quantityDate] += 1
        } else {
          counter[quantityDate] = 1
        }
      })
      let quantityDateArrValue = []
      for (const [key, value] of Object.entries(counter)) {
        quantityDateArrValue.push({
          date: key,
          quantity: value,
        })
      }
      setQuantityDateArr(quantityDateArrValue)
    }
  }, [])

  return (
    <>
      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>

        {roleId == 7 &&
        <>
          <div className='col-xxl-12'>
            {!currentUser.user.expired_date &&
              <p className='fs-2 text-danger'>Please renew your membership with 10 usdt/month or 100 usdt/year package to receive commission!</p>
            }
            {(currentUser.user.expired_date && parseInt(currentUser.user.expired_date) < parseInt(moment().format('YYYYMMDD'))) &&
              <div>
                <p className='fs-2 text-success'>Membership benefits are extended until: {currentUser.user.expired_date.toString().substr(6,2) + '/' + currentUser.user.expired_date.toString().substr(4,2) + '/' + currentUser.user.expired_date.toString().substr(0,4)}</p>
                <p className='fs-2 text-warning'>Please renew your membership with 10 usdt/month or 100 usdt/year package to receive commission!</p>
              </div>
            }
            {(currentUser.user.expired_date && parseInt(currentUser.user.expired_date) >= parseInt(moment().format('YYYYMMDD'))) &&
              <p className='fs-2 text-success'>Membership benefits are extended until: {currentUser.user.expired_date.toString().substr(6,2) + '/' + currentUser.user.expired_date.toString().substr(4,2) + '/' + currentUser.user.expired_date.toString().substr(0,4)}</p>
            }
          </div>

          <div className='col-xxl-12'>
            <MixedWidget2
              className='card-xl-stretch mb-xl-8'
              chartColor='danger'
              chartHeight='100px'
              strokeColor='#cb1e46'
              fullname={currentUser.user.name}
              username={currentUser.user.username}
              email={currentUser.user.email}
              code={currentUser.user.scan_code}
            />
          </div>

          <div className='col-12'>
            <div className={`card card-xxl-stretch`}>
              {/* begin::Header */}
              <div className='card-header align-items-center border-0 mt-4'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='fw-bolder mb-2 text-dark'>Refer Us & Earn</span>
                  <span className='text-warning fw-bold fs-3'>Use the below link to invite your friends.</span>
                </h3>
                <div className='card-toolbar'>
                  {/* begin::Menu */}
                  <button
                      type='button'
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                  >
                    <KTSVG
                        path='/media/icons/duotone/Layout/Layout-4-blocks-2.svg'
                        className='svg-icon-2'
                    />
                  </button>
                  {/* end::Menu */}
                </div>
              </div>
              {/* end::Header */}
              {/* begin::Body */}
              <div className='card-body pt-5'>
                <p className='text-primary'>{'https://robotanan.net/auth/registration?partner=' + currentUser.user.username}</p>
                <Button variant={!isCopied ? 'primary' : 'success'} size="sm" onClick={() => {
                  setIsCopied(true)
                  navigator.clipboard.writeText('https://robotanan.net/auth/registration?partner=' + currentUser.user.username)
                }}>
                  {!isCopied ? 'Copy Address' : 'Copied'}
                </Button>
              </div>
              {/* end: Card Body */}
            </div>
          </div>

          <div className='col-xxl-4'>
            <div className={`card card-xxl-stretch`}>
              {/* begin::Header */}
              <div className='card-header align-items-center border-0 mt-4'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='fw-bolder mb-2 text-dark'>Deposit History</span>
                  <span className='text-warning fw-bold fs-3'>Summary {depositSum} USDT</span>
                </h3>
                <div className='card-toolbar'>
                  {/* begin::Menu */}
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <KTSVG
                      path='/media/icons/duotone/Layout/Layout-4-blocks-2.svg'
                      className='svg-icon-2'
                    />
                  </button>
                  {/* end::Menu */}
                </div>
              </div>
              {/* end::Header */}
              {/* begin::Body */}
              <div className='card-body pt-5'>
                {depositHistoryArr.length < 1 && <>
                  <div className='fw-mormal timeline-content text-muted ps-3'>
                    No information
                  </div>
                </>}

                {depositHistoryArr.length >= 1 && <>
                  <div className='timeline-label'>

                    {depositHistoryArr.map((depositHistory) =>
                      <div className='timeline-item' key={depositHistory.id}>
                        <div className='timeline-label fw-bolder text-gray-800 fs-6 w-60px'>
                          + {depositHistory.usdt}
                        </div>
                        <div className='timeline-badge'>
                          <i className='fa fa-genderless text-warning fs-1'></i>
                        </div>
                        <div className='fw-mormal timeline-content text-muted ps-3'>
                          {depositHistory.change_date.toString().substr(6,2) + '/' + depositHistory.change_date.toString().substr(4,2) + '/' + depositHistory.change_date.toString().substr(0,4)}
                        </div>
                      </div>
                    )}

                  </div>
                </>}


              </div>
              {/* end: Card Body */}
            </div>
          </div>

          <div className='col-xxl-4'>
            <div className={`card card-xxl-stretch`}>
              {/* begin::Header */}
              <div className='card-header align-items-center border-0 mt-4'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='fw-bolder mb-2 text-dark'>Anan Stock History</span>
                  <span className='text-primary fw-bold fs-3'>Summary {stockDepositSum} STOCKS</span>
                </h3>
                <div className='card-toolbar'>
                  {/* begin::Menu */}
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <KTSVG
                      path='/media/icons/duotone/Layout/Layout-4-blocks-2.svg'
                      className='svg-icon-2'
                    />
                  </button>
                  {/* end::Menu */}
                </div>
              </div>
              {/* end::Header */}
              {/* begin::Body */}
              <div className='card-body pt-5'>
                {stockHistoryArr.length < 1 && <>
                  <div className='fw-mormal timeline-content text-muted ps-3'>
                    No information
                  </div>
                </>}

                {stockHistoryArr.length >= 1 && <>
                  <div className='timeline-label'>

                    {stockHistoryArr.map((stockHistory) => {
                      if (!stockHistory.partner_id) {
                        return (<div className='timeline-item' key={stockHistory.id}>
                          <div className='timeline-label fw-bolder text-gray-800 fs-6 w-60px'>
                            + {stockHistory.stock_quantity}
                          </div>
                          <div className='timeline-badge'>
                            <i className='fa fa-genderless text-warning fs-1'></i>
                          </div>
                          <div className='fw-mormal timeline-content text-muted ps-3'>
                            {stockHistory.change_date.toString().substr(6,2) + '/' + stockHistory.change_date.toString().substr(4,2) + '/' + stockHistory.change_date.toString().substr(0,4)}
                          </div>
                        </div>)
                      }
                    }
                    )}

                  </div>
                </>}


              </div>
              {/* end: Card Body */}
            </div>
          </div>

          <div className='col-xxl-4'>
            <div className={`card card-xxl-stretch`}>
              {/* begin::Header */}
              <div className='card-header align-items-center border-0 mt-4'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='fw-bolder mb-2 text-dark'>Anbi Account History</span>
                  <span className='text-danger fw-bold fs-3'>Summary {accountSum} ACCOUNTS</span>
                </h3>
                <div className='card-toolbar'>
                  {/* begin::Menu */}
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <KTSVG
                      path='/media/icons/duotone/Layout/Layout-4-blocks-2.svg'
                      className='svg-icon-2'
                    />
                  </button>
                  {/* end::Menu */}
                </div>
              </div>
              {/* end::Header */}
              {/* begin::Body */}
              <div className='card-body pt-5'>
                {accountHistoryArr.length < 1 && <>
                  <div className='fw-mormal timeline-content text-muted ps-3'>
                    No information
                  </div>
                </>}

                {accountHistoryArr.length >= 1 && <>
                  <div className='timeline-label'>

                    {accountHistoryArr.map((accountHistory) =>
                      <div className='timeline-item' key={accountHistory.id}>
                        <div className='timeline-label fw-bolder text-gray-800 fs-6 w-60px'>
                          + {accountHistory.account_quantity}
                        </div>
                        <div className='timeline-badge'>
                          <i className='fa fa-genderless text-warning fs-1'></i>
                        </div>
                        <div className='fw-mormal timeline-content text-muted ps-3'>
                          {accountHistory.change_date.toString().substr(6,2) + '/' + accountHistory.change_date.toString().substr(4,2) + '/' + accountHistory.change_date.toString().substr(0,4)}
                        </div>
                      </div>
                    )}

                  </div>
                </>}


              </div>
              {/* end: Card Body */}
            </div>
          </div>

          <div className='col-xxl-4'>
            <div className={`card card-xxl-stretch`}>
              {/* begin::Header */}
              <div className='card-header align-items-center border-0 mt-4'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='fw-bolder mb-2 text-dark'>Anan Commision History</span>
                  <span className='text-danger fw-bold fs-3'>Summary {commissionSum.toFixed(2)} USDT Commission</span>
                </h3>
                <div className='card-toolbar'>
                  {/* begin::Menu */}
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <KTSVG
                      path='/media/icons/duotone/Layout/Layout-4-blocks-2.svg'
                      className='svg-icon-2'
                    />
                  </button>
                  {/* end::Menu */}
                </div>
              </div>
              {/* end::Header */}
              {/* begin::Body */}
              <div className='card-body pt-5'>
                {commissionArr.length < 1 && <>
                  <div className='fw-mormal timeline-content text-muted ps-3'>
                    No information
                  </div>
                </>}

                {commissionArr.length >= 1 && <>
                  <div className='timeline-label'>

                    {commissionArr.map((commission) =>
                      <div className='timeline-item' key={commission.id}>
                        <div className='timeline-label fw-bolder text-gray-800 fs-6 w-60px'>
                          {commission.value > 0 ? '+' : ''} {commission.value}
                        </div>
                        <div className='timeline-badge'>
                          <i className='fa fa-genderless text-warning fs-1'></i>
                        </div>
                        <div className='fw-mormal timeline-content text-muted ps-3'>
                          {commission.change_date.toString().substr(6,2) + '/' + commission.change_date.toString().substr(4,2) + '/' + commission.change_date.toString().substr(0,4) + ' - ' + commission.partner.username}
                        </div>
                      </div>
                    )}

                  </div>
                </>}


              </div>
              {/* end: Card Body */}
            </div>
          </div>

          <div className='col-xxl-4'>
            <div className={`card card-xxl-stretch`}>
              {/* begin::Header */}
              <div className='card-header align-items-center border-0 mt-4'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='fw-bolder mb-2 text-dark'>Anan Stock Commission History</span>
                  <span className='text-primary fw-bold fs-3'>Summary {stockCommissionSum} STOCKS Commission</span>
                </h3>
                <div className='card-toolbar'>
                  {/* begin::Menu */}
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <KTSVG
                      path='/media/icons/duotone/Layout/Layout-4-blocks-2.svg'
                      className='svg-icon-2'
                    />
                  </button>
                  {/* end::Menu */}
                </div>
              </div>
              {/* end::Header */}
              {/* begin::Body */}
              <div className='card-body pt-5'>
                {stockHistoryArr.length < 1 && <>
                  <div className='fw-mormal timeline-content text-muted ps-3'>
                    No information
                  </div>
                </>}

                {stockHistoryArr.length >= 1 && <>
                  <div className='timeline-label'>

                    {stockHistoryArr.map((stockHistory) => {
                      if (stockHistory.partner_id) {
                        return (<div className='timeline-item' key={stockHistory.id}>
                          <div className='timeline-label fw-bolder text-gray-800 fs-6 w-60px'>
                            + {stockHistory.stock_quantity}
                          </div>
                          <div className='timeline-badge'>
                            <i className='fa fa-genderless text-warning fs-1'></i>
                          </div>
                          <div className='fw-mormal timeline-content text-muted ps-3'>
                            {stockHistory.change_date.toString().substr(6, 2) + '/' + stockHistory.change_date.toString().substr(4, 2) + '/' + stockHistory.change_date.toString().substr(0, 4) + ' - ' + stockHistory.partner.username}
                          </div>
                        </div>)
                      }

                      }
                    )}

                  </div>
                </>}


              </div>
              {/* end: Card Body */}
            </div>
          </div>

          <div className='col-xxl-4'>
            <div className={`card card-xxl-stretch`}>
              {/* begin::Header */}
              <div className='card-header align-items-center border-0 mt-4'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='fw-bolder mb-2 text-dark'>Anan Withdraw History</span>
                  <span className='text-danger fw-bold fs-3'>Received {withdrawSum} USDT</span>
                </h3>
                <div className='card-toolbar'>
                  {/* begin::Menu */}
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <KTSVG
                      path='/media/icons/duotone/Layout/Layout-4-blocks-2.svg'
                      className='svg-icon-2'
                    />
                  </button>
                  {/* end::Menu */}
                </div>
              </div>
              {/* end::Header */}
              {/* begin::Body */}
              <div className='card-body pt-5'>
                {withdrawHistoryArr.length < 1 && <>
                  <div className='fw-mormal timeline-content text-muted ps-3'>
                    No information
                  </div>
                </>}

                {withdrawHistoryArr.length >= 1 && <>
                  <div className='timeline-label'>

                    {withdrawHistoryArr.map((withdraw) =>
                      <div className='timeline-item' key={withdraw.id}>
                        <div className='timeline-label fw-bolder text-gray-800 fs-6 w-60px'>
                          - {withdraw.value}
                        </div>
                        <div className='timeline-badge'>
                          <i className='fa fa-genderless text-warning fs-1'></i>
                        </div>
                        <div className='fw-mormal timeline-content text-muted ps-3'>
                          {withdraw.change_date.toString().substr(6,2) + '/' + withdraw.change_date.toString().substr(4,2) + '/' + withdraw.change_date.toString().substr(0,4) + ' - '}
                          {withdraw.is_done ? <span className='text-success'>Success</span> : <span className='text-warning'>Pending</span>}
                          <br/>
                          {withdraw.wallet_address}
                        </div>
                      </div>
                    )}

                  </div>
                </>}


              </div>
              {/* end: Card Body */}
            </div>
          </div>

          <div className='col-xxl-4'>
            <div className={`card card-xxl-stretch`}>
              {/* begin::Header */}
              <div className='card-header align-items-center border-0 mt-4'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='fw-bolder mb-2 text-dark'>Anan System Tree</span>
                  <span className='text-danger fw-bold fs-3'>Manage your own system</span>
                </h3>
                <div className='card-toolbar'>
                  {/* begin::Menu */}
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <KTSVG
                      path='/media/icons/duotone/Layout/Layout-4-blocks-2.svg'
                      className='svg-icon-2'
                    />
                  </button>
                  {/* end::Menu */}
                </div>
              </div>
              {/* end::Header */}
              {/* begin::Body */}
              <div className='card-body pt-5'>
                {f1Arr.length < 1 && <>
                  <div className='fw-mormal timeline-content text-muted ps-3'>
                    No information
                  </div>
                </>}

                {f1Arr.length >= 1 && <div>
                  {f1Arr.map((f1) => <div key={f1.id} className='mb-5'>
                    <h3 className='text-primary'>&nbsp;&nbsp;+ {f1.username} - {f1.name}</h3>
                    {f1.f2 && <div>
                      {f1.f2.length >= 1 && <div>
                        {f1.f2.map((f2) =>
                          <div key={f2.id}>
                            <h4 className='text-warning'>&nbsp;&nbsp;&nbsp;&nbsp;- {f2.username} - {f2.name}</h4>

                            {f2.f3 && <div>
                              {f2.f3.length >= 1 && <div>
                                {f2.f3.map((f3) =>
                                  <div key={f3.id}>
                                    <h4 className='text-success'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {f3.username} - {f3.name}</h4>

                                    {f3.f4 && <div>
                                      {f3.f4.length >= 1 && <div>
                                        {f3.f4.map((f4) =>
                                          <div key={f4.id}>
                                            <h4 className='text-danger'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {f4.username} - {f4.name}</h4>

                                            {f4.f5 && <div>
                                              {f4.f5.length >= 1 && <div>
                                                {f4.f5.map((f5) =>
                                                  <div key={f5.id}>
                                                    <h4 className='text-success'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {f5.username} - {f5.name}</h4>

                                                    {f5.f6 && <div>
                                                      {f5.f6.length >= 1 && <div>
                                                        {f5.f6.map((f6) =>
                                                          <div key={f6.id}>
                                                            <h4 className='text-danger'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {f6.username} - {f6.name}</h4>



                                                          </div>
                                                        )}
                                                      </div>}
                                                    </div>}

                                                  </div>
                                                )}
                                              </div>}
                                            </div>}

                                          </div>
                                        )}
                                      </div>}
                                    </div>}

                                  </div>
                                )}
                              </div>}
                            </div>}

                          </div>
                        )}
                      </div>}
                    </div>}
                  </div>)}
                </div>}
              </div>
              {/* end: Card Body */}
            </div>
          </div>

        </>}

        <div className='col-xxl-12 text-center'>
          {roleId == 8 &&
          <>
            <h3 className='card-title fw-bolder'>Họ và tên: {currentUser.user.name}</h3>
            <h3 className='card-title fw-bolder'>Tên đăng nhập: {currentUser.user.username}</h3>
            <h3 className='card-title fw-bolder'>Email: {currentUser.user.email}</h3>
            <h3 className='card-title fw-bolder'>Mã số bí mật: {currentUser.user.scan_code}</h3>
            <h3 className='card-title fw-bolder'>ID: {currentUser.user.id}</h3>
          </>}
          <hr/>
          {roleId == 7 &&
          <>
            <Link to='/deposit'>
              <button type='button' className='btn btn-success mx-5'>
                Deposit
              </button>
            </Link>
            <hr/>
            <Link to='/withdraw'>
              <button type='button' className='btn btn-warning mx-5'>
                Withdraw
              </button>
            </Link>
            <hr/>
          </>
          }

          <Link to='/change-password'>
            <button type='button' className='btn btn-danger mx-5'>
              Change Password
            </button>
          </Link>
          <hr/>
          <button type='button' className='btn btn-primary' onClick={() => {
            dispatch(auth.actions.logout())
          }}>
            Logout
          </button>
          {/*table account quantity*/}
          {quantityDateArr.length > 0 && (
            <>
              <hr/>
              <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                    <tr className='fw-bolder text-muted'>
                      <th className='min-w-150px'>Ngày</th>
                      <th className='min-w-120px'>Số lượng tài khoản kích hoạt</th>
                    </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                    {quantityDateArr.reverse().map((quantityDate) => {
                      return (
                        <tr key={quantityDate.date}>
                          <td>
                            {quantityDate.date.substr(6,2) + '/' + quantityDate.date.substr(4,2) + '/' + quantityDate.date.substr(0,4)}
                          </td>
                          <td>
                            {quantityDate.quantity ?? ''}
                          </td>
                        </tr>
                      )
                    })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
                {/* end::Table container */}
              </div>
            </>
          )}

          {/*table account quantity*/}
        </div>
      </div>
      {/* end::Row */}
    </>
  )

}
const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
