import React, {useState, useEffect} from 'react'
import axios from "axios";
import {BACKEND_URL} from "../redux/AuthCRUD";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, useLocation } from "react-router-dom";
import {Button} from "react-bootstrap-v5";

export function Registration() {
  const history = useHistory()
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const partner = searchParams.get("partner");
  console.log({partner})

  const [isValidData, setIsValidData] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [otp, setOtp] = useState('')
  const [name, setName] = useState('')
  const [username, setUsername] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [visiblePassword, setVisiblePassword] = useState(false)
  const [partnerName, setPartnerName] = useState('')
  const [isSendOtp, setIsSendOtp] = useState(false);

  const handleConfirm = () => {
    setIsValidData(false)
    setErrorMsg('')
    axios.post(`${BACKEND_URL}/investor/register`, {
      name: name,
      username: username,
      email: email,
      password: newPassword,
      partner: partner,
      otp: otp,
    }).then(res => res.data)
      .then(resData => {
        if (resData.error_code === 1) {
          toast("Đăng ký thành công", {
            onClose: () => {
              history.push('/auth/login')
            }
          })
        } else {
          setErrorMsg(resData.message ?? 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
        }
      })
    //axios.post(`${BACKEND_URL}/test/register`, {}).then(() => {})
  }

  useEffect(() => {
    if (partner) {
      axios.post(`${BACKEND_URL}/investor/partner`, {
        partner: partner
      }).then(res => res.data)
          .then(resData => {
            console.log({resData})
            if (resData.error_code === 1) {
              if (resData.data.user) {
                let partnerName = resData.data.user.username ?? ''
                if (partnerName) {
                  setPartnerName(partnerName)
                }
              }
            }
          })
    }
  }, [])

  useEffect(() => {
    if (name && email && username && newPassword && confirmNewPassword && newPassword === confirmNewPassword && otp) {
      setIsValidData(true)
    } else {
      setIsValidData(false)
    }
  }, [name, email, username, newPassword, confirmNewPassword, otp])

  return (
    <>
      <div
        className='form w-100'
      >
        {/* begin::Heading */}
        <div className='text-center mb-10'>
          {partnerName &&
              <h2 className='text-danger mb-3'>Welcome {partnerName}'s partner</h2>
          }
          <h1 className='text-primary mb-3'>Đăng ký</h1>
        </div>
        {/* begin::Heading */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Họ và tên</label>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            className='form-control form-control-lg form-control-solid'
            type='text'
            autoComplete='off'
          />
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className='form-control form-control-lg form-control-solid'
            type='text'
            autoComplete='off'
          />
        </div>
        {/* end::Form group */}

        <div className='text-center'>
          {(!isSendOtp && email) &&
          <Button className='my-3' variant='primary' size="sm" onClick={() => {
            setIsSendOtp(true)
            axios.post(`${BACKEND_URL}/send-otp-anbi`, {
              email: email,
            }).then(res => res.data)
              .then(resData => {
                if (resData.error_code === 1) {
                  toast("Gửi Otp thành công", {})
                } else {
                  toast("Gửi Otp không thành công, vui lòng liên hệ admin", {})
                }
              })
          }}>
            Send OTP
          </Button>
          }
        </div>

        {isSendOtp &&
        <>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>Otp</label>
            <input
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              className='form-control form-control-lg form-control-solid'
              type='text'
              autoComplete='off'
            />
          </div>
        </>
        }

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Tên đăng nhập</label>
          <input
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className='form-control form-control-lg form-control-solid'
            type='text'
            autoComplete='off'
          />
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Mật khẩu</label>
          <label
            onClick={() => setVisiblePassword(!visiblePassword)}
            style={{ cursor: 'pointer', float: 'right', color: 'blue' }}
          >
            {visiblePassword
              ?
              'Ẩn mật khẩu'
              :
              'Hiện mật khẩu'
            }
          </label>
          <input
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className='form-control form-control-lg form-control-solid'
            type={visiblePassword ? 'text' : 'password'}
            autoComplete='off'
          />
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Xác nhận mật khẩu</label>
          <input
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            className='form-control form-control-lg form-control-solid'
            type={visiblePassword ? 'text' : 'password'}
            autoComplete='off'
          />
        </div>
        {/* end::Form group */}

        {errorMsg && (<h4 style={{ color: 'red' }} >{errorMsg}</h4>)}

        {/* begin::Action */}
        <div className='text-center'>
          <button
            disabled={isValidData ? '' : 'disabled'}
            type='button'
            className='btn btn-lg btn-primary w-100 mb-5'
            onClick={() => handleConfirm()}
          >
            <span className='indicator-label'>Đăng ký</span>
          </button>
        </div>
        {/* end::Action */}
      </div>
      <ToastContainer />
    </>
  )
}
