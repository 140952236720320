/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import axios from "axios";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {useHistory, Link} from 'react-router-dom'
import {BACKEND_URL} from "../../modules/auth/redux/AuthCRUD";

type Props = {
  className: string
}

const TableSale: React.FC<Props> = ({className}) => {
  const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)
  const [sales, setSales] = useState([])
  const history = useHistory()

  useEffect(() => {
    axios.post(`${BACKEND_URL}/sale/read`, {
      access_token: accessToken
    }).then(data => data.data)
      .then(resData => {
        if (resData.error_code === 1) {
          setSales(resData.data.sales)
        }
      })
    return () => {}
  }, [])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Danh sách cộng tác viên</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Thêm cộng tác viên mới'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_invite_friends'
            onClick={(e) => {
              e.preventDefault()
              history.push('/create-sale')
            }}
          >
            <KTSVG path='media/icons/duotone/Communication/Add-user.svg' className='svg-icon-3' />
            Thêm cộng tác viên mới
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
            <tr className='fw-bolder text-muted'>
              <th className='min-w-150px'>Tên cộng tác viên</th>
              <th className='min-w-120px'>Định danh cộng tác viên</th>
              <th className='min-w-140px'>Số điện thoại</th>
              <th className='min-w-120px'>Mã bí mật</th>
              <th className='min-w-120px'>Tỉnh/Thành phố</th>
              <th className='min-w-120px'>Số lượng TK còn lại</th>
              <th className='min-w-100px text-end'>Hành động</th>
            </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {sales.map((sale: any) => {
              const accountQuantityArr = sale.sale_account_quantity
              return (
                <tr key={sale.id}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                          {sale.name ?? ''}
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                      {sale.alias ?? ''}
                    </a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                      {sale.phone ?? ''}
                    </a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                      {sale.scan_code ?? ''}
                    </a>
                  </td>
                  <td className='text-end'>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                          {sale.province.name ?? ''}
                        </a>
                      </div>
                    </div>
                  </td>
                  <td className='text-end'>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                          {sale.current_quantity}
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      <Link to={`/view-sale/${sale.id}`}>
                        <div
                          title='Xem chi tiết cộng tác viên'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTSVG
                            path='/media/icons/duotone/General/Settings-1.svg'
                            className='svg-icon-3'
                          />
                        </div>
                      </Link>
                      <Link to={`/edit-sale/${sale.id}`}>
                        <div
                          title='Sửa thông tin cộng tác viên'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTSVG
                            path='/media/icons/duotone/Communication/Write.svg'
                            className='svg-icon-3'
                          />
                        </div>
                      </Link>
                    </div>
                  </td>
                </tr>
              )
            })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TableSale}
