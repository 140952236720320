/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../../setup";

export function AsideMenuMain() {
  const intl = useIntl()
  const authInfo: any = useSelector<RootState>(({auth}) => auth, shallowEqual)
  let authRoleID = undefined
  if (authInfo) {
    authRoleID = authInfo.user.user.auth_role_id
  }

  return (
    <>
      {/*investor nha dau tu*/}
      {authRoleID === 7 &&
      <>
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/deposit'
          icon='/media/icons/duotone/Shopping/Wallet.svg'
          title={intl.formatMessage({id: 'MENU.DEPOSIT'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/withdraw'
          icon='/media/icons/duotone/Shopping/Ticket.svg'
          title={intl.formatMessage({id: 'MENU.WITHDRAW'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/transfer'
          icon='/media/icons/duotone/Shopping/Ticket.svg'
          title={intl.formatMessage({id: 'MENU.TRANSFER'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/buy-internal'
          icon='/media/icons/duotone/Shopping/Ticket.svg'
          title={intl.formatMessage({id: 'MENU.BUY_INTERNAL'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/anbi-code'
          icon='/media/icons/duotone/Shopping/Ticket.svg'
          title={intl.formatMessage({id: 'MENU.ANBI_CODE'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/change-password'
          icon='/media/icons/duotone/Code/Lock-circle.svg'
          title={intl.formatMessage({id: 'MENU.CHANGE_PASSWORD'})}
          fontIcon='bi-app-indicator'
        />
        {/*<AsideMenuItem
          to='/shopping'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.SHOPPING'})}
          fontIcon='bi-app-indicator'
        />*/}
      </>
      }

      {/*ke toan*/}
      {authRoleID === 8 &&
      <>
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/manage-investor'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.MANAGE_INVESTOR'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/manage-withdraw'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.MANAGE_WITHDRAW'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/manage-transaction'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.MANAGE_TRANSACTION'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/outside-list'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.MANAGE_INVESTOR_OUTSIDE'})}
          fontIcon='bi-app-indicator'
        />
      </>
      }

      {authRoleID === 1&&
      <>
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/manage-shareholder'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.MANAGE_SHAREHOLDER'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/admin-approve-quantity'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={'Duyệt số lượng tài khoản'}
          fontIcon='bi-app-indicator'
        />
      </>
      }

      {authRoleID === 6&&
      <>
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/manage-shareholder-by-director'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.MANAGE_SHAREHOLDER'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/shareholder-approve-quantity'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.APPROVE_SHAREHOLDER_QUANTITY'})}
          fontIcon='bi-app-indicator'
        />
      </>
      }

      {authRoleID === 2&&
      <>
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/manage-agency'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.MANAGE_AGENCY'})}
          fontIcon='bi-app-indicator'
        />
      </>
      }

      {authRoleID === 3&&
      <>
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/manage-customer'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.MANAGE_CUSTOMER'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/manage-sale'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.MANAGE_SALE'})}
          fontIcon='bi-app-indicator'
        />
      </>
      }

      {authRoleID === 5 &&
      <>
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/manage-customer'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.MANAGE_CUSTOMER'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/approve-customer'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.APPROVE_CUSTOMER'})}
          fontIcon='bi-app-indicator'
        />
      </>
      }

    </>
  )
}
