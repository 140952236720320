/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import axios from "axios";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {useHistory, Link} from 'react-router-dom'
import {BACKEND_URL} from "../../modules/auth/redux/AuthCRUD";
import moment from "moment";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const TableWithdraw = ({className}) => {
  const accessToken = useSelector(({auth}) => auth.accessToken, shallowEqual)
  const [withdrawList, setWithdrawList] = useState([])
  console.log(withdrawList)
  const history = useHistory()

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)

  useEffect(() => {
    axios.post(`${BACKEND_URL}/investor/withdraw-list`, {
      access_token: accessToken
    }).then(data => data.data)
      .then(resData => {
        if (resData.error_code === 1) {
          setWithdrawList(resData.data.withdraw_list)
        }
      })
  }, [])

  return (
    <div className={`card ${className}`}>
      <ToastContainer />
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Danh sách lệnh rút tiền</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-110px'>ID</th>
                <th className='min-w-140px'>Địa chỉ ví</th>
                <th className='min-w-150px'>Số tiền</th>
                <th className='min-w-150px'>Ngày tạo lệnh</th>
                <th className='min-w-150px text-end'>Trạng thái</th>
                <th className='min-w-100px text-end'>Thao tác</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {withdrawList.length < 1 &&
                <tr>
                  <td className='w-100'>
                    <h3>Không có lệnh rút tiền cần thực hiện</h3>
                  </td>
                </tr>
              }
              {withdrawList.length >= 1 && withdrawList.map((withdraw) =>
                <tr key={withdraw.id}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fw-bolder text-hover-primary fs-6'>
                          {withdraw.id ?? ''}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className='text-end'>
                    <div className='d-flex justify-content-start flex-row align-items-center'>
                        <span className='text-dark fw-bolder text-hover-primary fs-6'>
                          {withdraw.wallet_address ?? ''}
                        </span>
                      <button onClick={() => { navigator.clipboard.writeText(withdraw.wallet_address) }} className='btn btn-sm btn-primary ms-3'>Copy</button>
                    </div>
                  </td>
                  <td className='text-start'>
                    <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fw-bolder text-hover-primary fs-6'>
                          {withdraw.value + ' USDT' ?? ''}
                        </span>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex justify-content-end flex-column'>
                        <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                          {withdraw.change_date.toString().slice(6, 8) + '/' + withdraw.change_date.toString().slice(4, 6) + '/' + withdraw.change_date.toString().slice(0, 4) ?? ''}
                        </span>
                    </div>
                  </td>
                  <td className='text-end'>
                    <div className='d-flex justify-content-start flex-column'>
                        <span className={withdraw.is_done == 1 ? 'text-success fw-bolder text-hover-primary fs-6' : 'text-warning fw-bolder text-hover-primary fs-6'}>
                          {withdraw.is_done == 1 ? 'Đã chuyển tiền' : 'Chờ xác nhận'}
                        </span>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      {withdraw.is_done != 1 &&
                        <button className='btn btn-primary' onClick={() => {
                          if (window.confirm("Bạn chắc chắn đã tạo lệnh chuyển tiền?") == true) {
                            // cập nhật db
                            console.log(`${BACKEND_URL}/investor/approve-withdraw/${withdraw.id}`)
                            axios.post(`${BACKEND_URL}/investor/approve-withdraw/${withdraw.id}`, {
                              access_token: accessToken
                            }).then(data => data.data)
                              .then(resData => {
                                console.log({resData})
                                if (resData.error_code === 1) {
                                  // cập nhật thành công
                                  toast("Cập nhật lệnh rút tiền thành công", {
                                    onClose: () => {
                                      window.location.reload()
                                    }
                                  })
                                } else {
                                  toast("Cập nhật lệnh rút tiền thất bại", {
                                    onClose: () => {
                                      window.location.reload()
                                    }
                                  })
                                }
                              })
                          } else {
                            return false
                          }
                        }}>
                          Xác nhận đã chuyển tiền
                        </button>
                      }
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TableWithdraw}
