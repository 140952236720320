import React, {useEffect, useState} from 'react'
import { Button} from 'react-bootstrap-v5';
import { useSelector } from "react-redux";
import axios from "axios";
import {BACKEND_URL} from "../../modules/auth/redux/AuthCRUD";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from "react-router-dom";
import {KTSVG} from "../../../_metronic/helpers";

const AnbiCode = () => {
  const currentUser = useSelector(({auth}) => auth.user.user)
  console.log(currentUser)
  const [hasError, setHasError] = useState(true);
  const [isSendOtp, setIsSendOtp] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [receiverUsername, setReceiverUsername] = useState('');
  const [receiverName, setReceiverName] = useState('');
  const [transferValue, setTransferValue] = useState(0);
  const [otp, setOtp] = useState('');
  const [anbiLink, setAnbiLink] = useState('');
  const history = useHistory()
  const userByInvestorArr = currentUser.anbi ?? []

  let accountSum = 0;
  const accountHistoryArr = currentUser.investor_account_quantity ?? []
  if (accountHistoryArr.length >= 1) {
    accountHistoryArr.forEach((accountHistory) => accountSum += accountHistory.account_quantity)
  }

  useEffect(() => {
      //axios.post(`${BACKEND_URL}/test/withdraw`, {}).then(() => {})
  }, [])

  useEffect(() => {
    if (otp) {
      setHasError(false)
    } else {
      setHasError(true)
    }
    //axios.post(`${BACKEND_URL}/test/withdraw`, {}).then(() => {})
  }, [otp])

  return (
    <>
      <ToastContainer />
      <h1 className='text-danger fs-2 text-center'>Generate Anbi Register Link</h1>
      <h1 className='text-primary fs-3 text-center'>Sum {accountSum} - Used {userByInvestorArr.length} - Available {accountSum - userByInvestorArr.length}</h1>
      <div className='text-center'>
        {!isSendOtp &&
        <Button className='my-3' variant='primary' size="sm" onClick={() => {
          setIsSendOtp(true)
          axios.post(`${BACKEND_URL}/send-otp`, {
            email: currentUser.email,
          }).then(res => res.data)
            .then(resData => {
              if (resData.error_code === 1) {
                toast("Gửi Otp thành công", {})
              } else {
                toast("Gửi Otp không thành công, vui lòng liên hệ admin", {})
              }
            })
        }}>
          Send OTP
        </Button>
        }

        {isSendOtp &&
          <>
            <div className='fv-row mb-10 text-center'>
              <label className='form-label fs-6 fw-bolder text-primary fs-2'>OTP</label>
              <input
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className='form-control form-control-lg form-control-solid bg-secondary fs-3'
                type='text'
                autoComplete='off'
              />
            </div>

            {errorMsg &&
            <p className='text-danger fs-3'>{errorMsg}</p>
            }

            {anbiLink &&
              <>
                <a href={anbiLink} target='_blank' className='text-danger fs-1'>{anbiLink}</a>
                <p className='text-success fs-3'>Send link to your friend to scan Anbi</p>
                <button onClick={() => { navigator.clipboard.writeText(anbiLink) }} className='btn btn-sm btn-primary my-3'>Copy Link</button>
              </>
            }

            <div className='text-center'>
              <Button disabled={!hasError ? '' : 'disabled'} variant='success' size="sm" onClick={() => {
                setErrorMsg('')
                setHasError(true)
                axios.post(`${BACKEND_URL}/investor/take-anbi-link`, {
                  access_token: currentUser.access_token,
                  otp: otp,
                }).then(res => res.data)
                  .then(resData => {
                    if (resData.error_code === 1) {
                      setAnbiLink(resData.data.link)
                    } else {
                      setErrorMsg(resData.message)
                      setHasError(false)
                    }
                  })
                  .catch(err => {
                    toast("Tạo link Anbi thất bại, vui lòng liên hệ quản trị viên", {})
                  })
              }}>
                Generate Link
              </Button>
            </div>
          </>
        }
      </div>


      <div className='row gy-5 g-xl-8 mt-5'>
        <div className='col-xxl-4'>
          <div className={`card card-xxl-stretch`}>
            {/* begin::Header */}
            <div className='card-header align-items-center border-0 mt-4'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='fw-bolder mb-2 text-dark'>User Scan History</span>
              </h3>
              <div className='card-toolbar'>
                {/* begin::Menu */}
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <KTSVG
                    path='/media/icons/duotone/Layout/Layout-4-blocks-2.svg'
                    className='svg-icon-2'
                  />
                </button>
                {/* end::Menu */}
              </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body pt-5'>
              {userByInvestorArr.length < 1 && <>
                <div className='fw-mormal timeline-content text-muted ps-3'>
                  No information
                </div>
              </>}

              {userByInvestorArr.length >= 1 && <>
                <div className='timeline-label'>

                  {userByInvestorArr.map((user) =>
                    <div className='timeline-item' key={user.id}>
                      <div className='timeline-label fw-bolder text-gray-800 fs-6 w-60px'>
                        ID {user.id}
                      </div>
                      <div className='timeline-badge'>
                        <i className='fa fa-genderless text-warning fs-1'></i>
                      </div>
                      <div className='fw-mormal timeline-content text-muted ps-3'>
                        {user.phone} - {user.name}
                      </div>
                    </div>
                  )}

                </div>
              </>}
            </div>
            {/* end: Card Body */}
          </div>
        </div>
      </div>
    </>
  )
}

export {AnbiCode}
